import { Card } from "primereact/card";
import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { IRecipient } from "../../interfaces/recipient";
import { Dropdown } from "primereact/dropdown";
import { statusList } from "../../constants";
import { AppLightTheme } from "../../utils/theme";
import "./style.css";
import { ITrigger } from "../../interfaces/trigger";

export default function PersonalForm(
  props:
    | {
        type: "recipient";
        data?: Partial<IRecipient>;
        setData: React.Dispatch<
          React.SetStateAction<Partial<IRecipient> | undefined>
        >;
        statusUpdate(resp?: Partial<IRecipient>): void;
      }
    | {
        type: "trigger";
        data?: Partial<ITrigger>;
        setData: React.Dispatch<
          React.SetStateAction<Partial<ITrigger> | undefined>
        >;
        statusUpdate(resp?: Partial<ITrigger>): void;
      }
) {
  // const [data, setRecipient] = useState<IRecipient>();
  // const [selectedStatusList, setSelectedStatusList] = useState<
  //   typeof statusList | null
  // >(null);
  const [selectedStatus, setSelectedStatus] = useState(
    (props.data && props.data.status) || null
  );

  const handleStatusChange = (e: { value: { id: string; name: string } }) => {
    const newStatus = e.value.id;
    setSelectedStatus(newStatus);
    // Update the data state based on the type
    if (props.type === "recipient") {
      props.setData((prevData) => ({
        ...prevData,
        status: newStatus,
      }));
      props.setData((prevData) => {
        props.statusUpdate(prevData);
        return prevData;
      });
    } else {
      props.setData((prevData) => ({
        ...prevData,
        status: newStatus,
      }));
      props.setData((prevData) => {
        props.statusUpdate(prevData);
        return prevData;
      });
    }
  };

  const header = (
    <div
      className="w-full flex align-items-center justify-content-left flex-wrap card-container mb-2"
      style={{ color: AppLightTheme.backgroundColor.NAV_BAR }}
    >
      <h3 className="my-0 mr-2">Personal Information</h3>
      <div className="flex-auto">
        <hr style={{ border: "1px solid" }} />
      </div>
    </div>
  );

  return (
    <Card className="m-4">
      {header}
      <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 py-2">
        <div className="flex-auto">
          <label htmlFor="fname" className="font-bold block mb-2">
            First Name
          </label>
          <InputText
            className="w-full"
            id="fname"
            disabled
            value={props.data?.FNAME || ""}
          />
        </div>
        <div className="flex-auto">
          <label htmlFor="lname" className="font-bold block mb-2">
            Last Name
          </label>
          <InputText
            className="w-full"
            id="lname"
            disabled
            value={props.data?.LNAME || ""}
          />
        </div>
        <div style={{ width: "32.2%" }}>
          <label htmlFor="status" className="font-bold block mb-2">
            Status
          </label>
          <Dropdown
            id="status"
            value={selectedStatus}
            options={statusList}
            onChange={handleStatusChange}
            optionLabel="name"
            placeholder={
              props.data && props.data.status ? props.data.status : "Select..."
            } //"Select..."
            className="w-full"
          />
        </div>
      </div>
      <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 py-2">
        <div className="flex-auto">
          <label htmlFor="address1" className="font-bold block mb-2">
            Address 1
          </label>
          {props.type === "recipient" ? (
            <InputText
              className="w-full"
              id="address1"
              disabled
              value={props.data?.ADDRESSLINE1 || ""}
            />
          ) : (
            <InputText
              className="w-full"
              id="address1"
              disabled
              value={props.data?.ADDRESS || ""}
            />
          )}
        </div>
        <div className="flex-auto">
          <label htmlFor="address2" className="font-bold block mb-2">
            Address 2
          </label>
          {props.type === "recipient" ? (
            <InputText
              className="w-full"
              id="address2"
              disabled
              value={props.data?.ADDRESSLINE2 || ""}
            />
          ) : (
            <InputText
              className="w-full"
              id="address2"
              disabled
              value={props.data?.ADDRESS2 || ""}
            />
          )}
        </div>
        <div className="flex-auto">
          <label htmlFor="city" className="font-bold block mb-2">
            City
          </label>
          <InputText
            className="w-full"
            id="city"
            disabled
            value={props.data?.CITY || ""}
          />
        </div>
      </div>
      <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 py-2">
        <div className="flex-auto">
          <label htmlFor="state" className="font-bold block mb-2">
            State
          </label>
          <InputText
            className="w-full"
            id="state"
            disabled
            value={props.data?.STATE || ""}
          />
        </div>
        <div className="flex-auto">
          <label htmlFor="zip" className="font-bold block mb-2">
            Zip
          </label>
          <InputText
            className="w-full"
            id="zip"
            disabled
            value={props.data?.ZIP || ""}
          />
        </div>
        <div className="flex-auto">
          <label htmlFor="country" className="font-bold block mb-2">
            Country
          </label>
          <InputText
            className="w-full"
            id="country"
            disabled
            value={props.data?.COUNTY || ""}
          />
        </div>
      </div>
      <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 py-2">
        <div className="flex-auto">
          <label htmlFor="phone" className="font-bold block mb-2">
            Phone
          </label>
          {props.type === "recipient" ? (
            <InputText
              className="w-full"
              id="phone"
              value={props.data?.PHONE_NUMBER || ""}
              onChange={(e) =>
                props.setData((p) => ({ ...p, PHONE_NUMBER: e.target.value }))
              }
            />
          ) : (
            <InputText
              className="w-full"
              id="phone"
              value={props.data?.PHONE_NUM || ""}
              onChange={(e) =>
                props.setData((p) => ({ ...p, PHONE_NUM: e.target.value }))
              }
            />
          )}
        </div>
        <div className="flex-auto">
          <label htmlFor="email" className="font-bold block mb-2">
            Email
          </label>
          <InputText
            className="w-full"
            id="email"
            value={props.data?.EMAIL || ""}
            onChange={(e) => {
              props.type === "recipient"
                ? props.setData((p) => ({
                    ...p,
                    EMAIL: e.target.value,
                  }))
                : props.setData((p) => ({
                    ...p,
                    EMAIL: e.target.value,
                  }));
            }}
          />
        </div>
        <div className="flex-auto">
          <label htmlFor="loassigned" className="font-bold block mb-2">
            Lo Assigned
          </label>
          <InputText
            className="w-full"
            id="loassigned"
            value={props.data?.LO_ASSIGNED || ""}
            onChange={(e) => {
              props.type === "recipient"
                ? props.setData((p) => ({
                    ...p,
                    LO_ASSIGNED: e.target.value,
                  }))
                : props.setData((p) => ({
                    ...p,
                    LO_ASSIGNED: e.target.value,
                  }));
            }}
          />
        </div>
      </div>
    </Card>
  );
}
