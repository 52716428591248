export const alertVariant = {
  success: "success",
  error: "error",
  warning: "warn",
  info: "info",
} as const;

export const ACTIONS = {
  SHOW: "SHOW",
} as const;
