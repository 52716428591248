import React from "react";
import { useAppSelector } from "../../store";

export default function Spinner() {
  const spinner = useAppSelector((state) => state.spinner);
  return (
    <div
      className={
        spinner.count ? "ui active page dimmer" : "ui inactive page dimmer"
      }
    >
      <div className="ui large text loader">{spinner.message}</div>
    </div>
  );
}
