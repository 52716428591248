import { ROOT_URL } from "../constants/aws_config";

export const fetchReportData = async (params: any) => {
  console.log("params", params);
  try {
    const url =
      ROOT_URL +
      `/report?tableName=${params.tableName}&category=${params.category}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    console.log("resultAPi Response", result);
    return result;
  } catch (error) {
    console.log(error);
  }
};
