/* eslint-disable prettier/prettier */
import { Dialog } from "primereact/dialog";
import React from "react";
import LineChart from "../charts/lineChart";
import ClientBarChart from "../charts/clientBarChart";

const ChartViewModal = ({
  visibleLine,
  visibleBar,
  setVisibleLine,
  setVisibleBar,
  stateChartdata,
  clientGraphData,
  type,
}: any) => {
  return (
    <Dialog
      visible={visibleLine || visibleBar}
      style={{ width: "90vw", height: "70vh" }}
      onHide={type === "line" ? setVisibleLine : setVisibleBar}
    >
      {type === "line" ? (
        <LineChart stateChartdata={stateChartdata} visible={visibleLine} />
      ) : (
        <ClientBarChart clientGraphData={clientGraphData} visible={visibleBar} />
      )}
    </Dialog>
  );
};

export default ChartViewModal;
