import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuth, IRootState } from "../../interfaces/auth";
import { LocalStorage, LocalStorageKeys } from "../../utils/localStorage";
import CognitoService from "../../services/cognitoService";
import { spinnerActions } from "../spinner/spinnerSlice";
import { alertActions } from "../alert/alertSlice";
import { alertVariant } from "../../constants/alert";
import { recipientActions } from "../recipient/recipientSlice";
import { inMarketActions } from "../inMarket/inMarketSlice";
import { reMarketActions } from "../reMarket/reMarketSlice";
import { CognitoIdentityServiceProvider } from "aws-sdk";
type IUserState = {
  auth: IAuth | null;
  rootState: IRootState | null;
  userAttributes: Record<string, string> | null;
  users: CognitoIdentityServiceProvider.ListUsersResponse;
};

function createInitialState(): IUserState {
  return {
    auth: LocalStorage.get(LocalStorageKeys.AUTH),
    rootState: LocalStorage.get(LocalStorageKeys.ROOT),
    userAttributes: LocalStorage.get(LocalStorageKeys.USER_ATTRIBUTES),
    users: { Users: [] },
  };
}
const initialState = createInitialState();
function updateAuth(state: IUserState, data: Partial<IAuth> | null) {
  if (!data) return state;
  const result = { ...state, auth: { ...state.auth, ...data } };
  LocalStorage.set(LocalStorageKeys.AUTH, result.auth);
  return result;
}
function updateRootState(state: IUserState, data: Partial<IRootState> | null) {
  if (!data) return state;
  const result = { ...state, rootState: { ...state.rootState, ...data } };
  LocalStorage.set(LocalStorageKeys.ROOT, result.rootState);
  return result;
}
function saveUserAttributes(
  state: IUserState,
  data: Record<string, string> | null
) {
  if (!data) return state;
  const attr = data;
  // const result = updateAuth(state, {
  //   firstName: attr["name"],
  //   lastName: attr["family_name"],
  // });
  // const result0 = updateRootState(result, {
  //   phone: attr["phone_number"],
  //   fullName: attr["name"] + " " + attr["family_name"],
  // });
  const result = {
    ...state,
    userAttributes: { ...attr },
  };
  LocalStorage.set(LocalStorageKeys.USER_ATTRIBUTES, result.userAttributes);
  return result;
}
// async function signIn(state: IUserState, username: string, password: string) {
//   const user = await CognitoService.signIn(username, password);
//   CognitoService.setUserObject(user, username);
//   const state0 = updateAuth(state, LocalStorage.get(LocalStorageKeys.AUTH));
//   const results = await CognitoService.getUserAttributes();
//   const attr = CognitoService.processUserAttributes(results);
//   const state1 = updateRootState(state0, {
//     authStatus: attr["custom:subscription_status"],
//   });
//   return saveUserAttributes(state1, attr);
// }
const signIn = createAsyncThunk(
  "user/signInAsync",
  async (
    { username, password }: { username: string; password: string },
    { dispatch }
  ) => {
    try {
      dispatch(spinnerActions.load({ message: "Authenticating..." }));
      const user = await CognitoService.signIn(username, password);
      CognitoService.setUserObject(user, username);
      const results = await CognitoService.getUserAttributes();
      const attr = CognitoService.processUserAttributes(results);
      dispatch(spinnerActions.unload());
      return attr;
    } catch (e) {
      if (e instanceof Error) {
        dispatch(spinnerActions.unload());
        dispatch(
          alertActions.show({
            variant: alertVariant.error,
            body: e.message || "Something is wrong",
          })
        );
        throw e;
      }
    }
  }
);
const signOut = createAsyncThunk(
  "user/signOutAsync",
  async ({ message }: { message?: string }, { dispatch }) => {
    try {
      dispatch(spinnerActions.load({ message: message || "Logging Out..." }));
      await CognitoService.signOut();
      LocalStorage.remove(LocalStorageKeys.AUTH);
      LocalStorage.remove(LocalStorageKeys.ROOT);
      LocalStorage.remove(LocalStorageKeys.USER_ATTRIBUTES);
      dispatch(spinnerActions.unload());
      // unload other states...
      dispatch(recipientActions.clear());
      dispatch(inMarketActions.clear());
      dispatch(reMarketActions.clear());
    } catch (e) {
      if (e instanceof Error) {
        dispatch(spinnerActions.unload());
        dispatch(
          alertActions.show({
            variant: alertVariant.error,
            body: e.message || "Something is wrong",
          })
        );
        throw e;
      }
    }
  }
);
const listUsers = createAsyncThunk(
  "user/listUsers",
  async (_noArg, { dispatch }) => {
    try {
      dispatch(spinnerActions.load({ message: "Requesting Data..." }));
      const results: CognitoIdentityServiceProvider.ListUsersResponse = {};
      let temp = await CognitoService.listUsers();
      while (temp.PaginationToken) {
        results.Users = [...(results.Users || []), ...(temp.Users || [])];
        temp = await CognitoService.listUsers(temp.PaginationToken);
      }
      results.Users = [...(results.Users || []), ...(temp.Users || [])];
      dispatch(spinnerActions.unload());
      return results;
    } catch (e) {
      if (e instanceof Error) {
        dispatch(spinnerActions.unload());
        dispatch(
          alertActions.show({
            variant: alertVariant.error,
            body: e.message || "Something is wrong",
          })
        );
        throw e;
      }
    }
  }
);
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateAuth(state: IUserState, action: PayloadAction<Partial<IAuth>>) {
      return updateAuth(state, action.payload);
    },
    updateRootState(
      state: IUserState,
      action: PayloadAction<Partial<IRootState>>
    ) {
      return updateRootState(state, action.payload);
    },
    saveUserAttributes(
      state: IUserState,
      action: PayloadAction<Record<string, string>>
    ) {
      return saveUserAttributes(state, action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(signIn.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const attr = action.payload;
      updateAuth(state, {
        ...LocalStorage.get<IAuth>(LocalStorageKeys.AUTH),
        firstName: attr["name"],
        lastName: attr["family_name"],
      });
      updateRootState(state, {
        authStatus: action.payload["custom:subscription_status"],
        phone: attr["phone_number"],
        fullName: attr["name"] + " " + attr["family_name"],
      });
      saveUserAttributes(state, action.payload);
      state = createInitialState();
      return { ...state };
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state = createInitialState();
      return { ...state };
    });
    builder.addCase(listUsers.fulfilled, (state, action) => {
      state = { ...state, users: action.payload || { Users: [] } };
      return state;
    });
  },
});

export const userActions = {
  ...userSlice.actions,
  signIn,
  signOut: (message?: string) => signOut({ message }),
  listUsers,
};

export default userSlice.reducer;
