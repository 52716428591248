import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/form/LoginForm";
import backgroundImage from "../assets/images/bgLogInImg.jpg";
import { userActions } from "../features/user/userSlice";
import { useAppDispatch, useAppSelector } from "../store";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { auth } = useAppSelector((state) => state.user);
  function authenticate(username: string, password: string) {
    // updateRootState({ spinner: true, progress: "Authenticating..." });
    dispatch(userActions.signIn({ username, password }));
  }

  useEffect(() => {
    if (auth) navigate("/");
  }, [auth]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        background: `url(${backgroundImage}) center center / cover no-repeat fixed`,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        marginLeft: 0,
      }}
    >
      <LoginForm onSubmit={authenticate} />
    </div>
  );
}
