import ReactEChart from "echarts-for-react";
export default function PiChart({ satusGraphData }: any) {
  console.log(
    "satusGraphData",
    satusGraphData?.[0]?.[0].map((i: any) => i)
  );
  const option = {
    title: {
      text: "Status",
      // subtext: "Fake Data",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      //   orient: "vertical",
      top: "bottom",
      left: "center",
    },
    series: [
      {
        name: "Status",
        type: "pie",
        radius: "50%",
        data: satusGraphData?.[0]?.[0].map((i: any) => ({
          value: i?.["count(*)"],
          name: i.status,
        })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <ReactEChart style={{ height: "100%", width: "100%" }} option={option} />
  );
}
