import React from "react";
import "./assets/css/style.css";
import RouterComponent from "./router";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
//primeflex
import "primeflex/primeflex.css";
//fontawesome
import "@fortawesome/fontawesome-free/css/all.min.css";

function App() {
  return (
    <div>
      <RouterComponent />
    </div>
  );
}

export default App;
