/* eslint-disable prettier/prettier */
import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { NavigationPagePaths } from "./navigationPagePath";
import CognitoService from "../services/cognitoService";
import { userActions } from "../features/user/userSlice";

export default function ProtectAdmin({
    children,
}: {
    children: JSX.Element;
}) {
    const { userAttributes } = useAppSelector((state) => state.user);
    const auth = userAttributes && userAttributes["custom:Camber"] === "1";
    // const dispatch = useAppDispatch();
    const location = useLocation();

    if (!auth) {
        // not logged in so redirect to login page with the return url
        return (
            <Navigate to={NavigationPagePaths.HOME} state={{ from: location }} />
        );
    }

    // authorized so return child components
    return children;
}
