export const NavigationPagePaths = {
  HOME: "/home",
  RESPONDER_PROFILES: "/responder-profiles",
  IN_MARKET_MONITORING: "/in-market-monitoring",
  RE_MARKETING: "/re-marketing",
  ADMIN: "/admin",
  USER_BY_ID: "/users/:id",
  TRIGGER_IN_MARKET_MONITORING_BY_ID: "/trigger/in-market-monitoring/:id",
  TRIGGER_RE_MARKETING_BY_ID: "/trigger/remarketing/:id",
  LOGIN: "/login",
  CHART_REPORT: "/chartreport",
} as const;

export const ExternalPaths = {
  CALL_CAMBERMARKETING: "https://calls.cambermarketing.com/",
  ZILLOW_URL: "https://www.zillow.com/",
  MORTGAGE_CALCULATOR: "https://www.calculator.net/mortgage-calculator.html",
  ZILLOW_URL_WITH_TOKEN:
    "https://api.bridgedataoutput.com/api/v2/zestimates_v2/zestimates?access_token=78aae91f27e04a4072aa9d0c67dc2025&address=",
} as const;
