export const AWS_CONFIG = {
  cognitoEndpoint: "cognito-idp.us-west-2.amazonaws.com",
  region: "us-west-2",
  userPoolId: "us-west-2_UH5htEBgL",
  clientId: "6dc08tpqqiefjp474ltvvdfqkv",
  identityPoolId: "us-west-2:82b4205c-d802-4ef0-9736-8f14b61bd8e2",
};

export const AWS_CREDENTIALS = {
  // old
  // accessKeyId: "AKIAJFPR4EBLQXXWIRIA",
  // secretAccessKey: "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",

  // new
  // accessKeyId: "AKIAWQAUAYSEEPIWCRYW",
  // secretAccessKey: "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",

  //latest by Sid sir
  accessKeyId: "AKIAWQAUAYSEKGLPK56Q",
  secretAccessKey: "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",
};

// Testing URL
// export const ROOT_URL =
//   "https://q90fxyezih.execute-api.ap-south-1.amazonaws.com/testing";

// Production URL
export const ROOT_URL =
  "https://wvomql1tjj.execute-api.us-west-2.amazonaws.com/Prod";
