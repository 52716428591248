import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { AppLightTheme } from "../../utils/theme";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAppDispatch, useAppSelector } from "../../store";
import { userActions } from "../../features/user/userSlice";
import CognitoService from "../../services/cognitoService";
import moment from "moment";
import { properCase, reduceArray } from "../../utils";
import Action from "./Action";
import Paginator from "./Paginator";
import NewUserForm from "../form/NewUserForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export default function AdminTable({
  searchKeyword,
}: {
  searchKeyword: string;
}) {
  const pageLimit = 10;
  const [userListData, setUserListData] = useState<
    Record<string, React.ReactNode>[]
  >([]);
  const [filteredData, setFilteredData] = useState<
    Record<string, React.ReactNode>[]
  >([]);
  const [tableData, setTableData] = useState<Record<string, React.ReactNode>[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [newUserModal, setNewUserModal] = useState(false);
  const { users } = useAppSelector((state) => state.user);
  const [showPasswords, setShowPasswords] = useState<{
    [key: string]: boolean;
  }>({});
  const dispatch = useAppDispatch();
  function checkTag(cond: string) {
    return cond === "1" ? (
      <>
        <i className="green checkmark icon"></i> True
      </>
    ) : (
      <>
        <i className="red x icon"></i> False
      </>
    );
  }
  const togglePasswordVisibility = (uniqueId: string) => {
    setShowPasswords((prevShowPasswords) => ({
      ...prevShowPasswords,
      [uniqueId]: !prevShowPasswords[uniqueId] || false,
    }));
  };
  useEffect(() => {
    dispatch(userActions.listUsers());
  }, []);
  useEffect(() => {
    setUserListData(
      users.Users?.map((user) => {
        const data: Record<string, string> = {
          ...CognitoService.processUserAttributes(user.Attributes || []),
          UserStatus: properCase(user.UserStatus) || "",
          UserCreateDate: moment(user.UserCreateDate).format("MM/DD/YYYY"),
        };
        return {
          ...data,
          email: data["email"].toLowerCase(),
          "custom:Camber": checkTag(data["custom:Camber"]),
          "custom:ResponderProfile": checkTag(data["custom:ResponderProfile"]),
          "custom:ReMarketing": checkTag(data["custom:ReMarketing"]),
          "custom:password": data["custom:password"],
          "custom:InMarketMonitoring": checkTag(
            data["custom:InMarketMonitoring"]
          ),
          action: <Action UserDetails={user} />,
        };
      }) || []
    );
  }, [users]);
  useEffect(() => {
    if (searchKeyword) {
      setFilteredData(
        userListData.filter(
          (data) =>
            ((data["custom:client_name"] as string) || "")
              .toLowerCase()
              .includes(searchKeyword.toLowerCase()) ||
            ((data["email"] as string) || "")
              .toLowerCase()
              .includes(searchKeyword.toLowerCase()) ||
            ((data["given_name"] as string) || "")
              .toLowerCase()
              .includes(searchKeyword.toLowerCase()) ||
            ((data["family_name"] as string) || "")
              .toLowerCase()
              .includes(searchKeyword.toLowerCase())
        )
      );
    } else {
      setFilteredData(userListData);
    }
  }, [userListData, searchKeyword]);
  useEffect(() => {
    if (searchKeyword != "" && filteredData.length <= 10) {
      setTableData(filteredData);
    } else {
      setTableData(
        reduceArray(filteredData, (currentPage - 1) * pageLimit, pageLimit)
      );
    }
  }, [filteredData, currentPage]);

  return (
    <Card className="m-4">
      <div className="flex justify-content-end flex-wrap card-container  w-full">
        <Button
          tooltip="Add a new User"
          tooltipOptions={{ position: "bottom" }}
          onClick={() => setNewUserModal(true)}
          style={{
            color: "white",
            backgroundColor: AppLightTheme.color.PRIMARY,
            borderColor: AppLightTheme.borderColor.PRIMARY,
          }}
        >
          <i className="fa fa-light fa-user-plus mr-2"></i> Add New User
        </Button>
      </div>
      <NewUserForm
        visible={newUserModal}
        onClose={setNewUserModal}
        onUserCreated={() => dispatch(userActions.listUsers())}
      />
      <DataTable
        value={tableData}
        stripedRows
        showGridlines
        tableStyle={{ minWidth: "50rem", fontSize: "smaller" }}
      >
        <Column field={"custom:client_name"} header="Client Name"></Column>
        <Column field={"given_name"} header="First Name"></Column>
        <Column field={"family_name"} header="Last Name"></Column>
        <Column field={"email"} header="Email"></Column>
        <Column field={"custom:branches"} header="Branches"></Column>
        <Column field={"UserStatus"} header="Status"></Column>
        <Column field={"UserCreateDate"} header="Created Date"></Column>
        <Column
          field={"custom:Camber"}
          header="Admin"
          headerStyle={{ minWidth: "70px", maxWidth: "6em" }}
        ></Column>
        <Column
          field={"custom:ResponderProfile"}
          header="Responder Profile"
          headerStyle={{ minWidth: "70px", maxWidth: "6em" }}
        ></Column>
        <Column
          field={"custom:ReMarketing"}
          header="Re-Marketing"
          headerStyle={{ minWidth: "70px", maxWidth: "6em" }}
        ></Column>
        <Column
          field={"custom:InMarketMonitoring"}
          header="In-Market Monitering"
          headerStyle={{ minWidth: "70px", maxWidth: "6em" }}
        ></Column>
        <Column
          header="Password"
          headerStyle={{ minWidth: "70px", maxWidth: "6em" }}
          body={(rowData) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FontAwesomeIcon
                onClick={() => togglePasswordVisibility(rowData?.sub)}
                icon={showPasswords[rowData?.sub] ? faEye : faEyeSlash}
                style={{ marginRight: "5px" }}
              />

              {showPasswords[rowData?.sub] ? (
                <>{rowData["custom:password"]}</>
              ) : null}
            </div>
          )}
        ></Column>

        <Column
          field={"action"}
          header="Actions"
          headerStyle={{ minWidth: "100px" }}
        ></Column>
      </DataTable>
      <div
        className="flex justify-content-center flex-wrap card-container  w-full"
        style={{ marginTop: "1.25rem" }}
      >
        <Paginator
          currentPage={currentPage}
          rowsPerPage={pageLimit}
          collectionLength={filteredData.length}
          enable={true}
          onPageChanged={setCurrentPage}
        />
      </div>
    </Card>
  );
}
