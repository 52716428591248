import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { AppLightTheme } from "../../utils/theme";
import { useAppDispatch } from "../../store";
import CognitoService from "../../services/cognitoService";
import { alertActions } from "../../features/alert/alertSlice";
import { spinnerActions } from "../../features/spinner/spinnerSlice";
import { LocalStorage, LocalStorageKeys } from "../../utils/localStorage";
import { CognitoIdentityServiceProvider } from "aws-sdk";
interface props {
  show: boolean;
  handleClose: () => void;
}
export default function VerifyCodeModal({ show, handleClose }: props) {
  const [email, setEmail] = React.useState("");
  const [code, setCode] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const dispatch = useAppDispatch();
  const handleSubmit = async () => {
    const UserAttributes: CognitoIdentityServiceProvider.AttributeType[] = [
      {
        Name: "custom:password",
        Value: newPassword,
      },
    ];
    await CognitoService.updateUserAttributes({
      Username: email,
      Attributes: UserAttributes,
    })
      .then(() => {
        CognitoService.confirmPassword(email, code, newPassword)
          .then(() => {
            dispatch(spinnerActions.unload());
            dispatch(
              alertActions.show({
                body: "successfully updated",
                variant: "success",
              })
            );
          })
          .catch((e) => {
            dispatch(spinnerActions.unload());
            dispatch(alertActions.show({ body: e.message, variant: "error" }));
          });
        handleClose();
      })
      .catch((e) => {
        dispatch(alertActions.show({ body: e.message, variant: "error" }));
      });
    // CognitoService.confirmPassword(email, code, newPassword)
    //   .then(() => {
    //     debugger;
    //     dispatch(spinnerActions.unload());
    //     dispatch(
    //       alertActions.show({
    //         body: "successfully updated",
    //         variant: "success",
    //       })
    //     );
    //   })
    //   .catch((e) => {
    //     debugger;
    //     dispatch(spinnerActions.unload());
    //     dispatch(alertActions.show({ body: e.message, variant: "error" }));
    //   });
    // handleClose();
  };
  return (
    <Dialog
      header={() => {
        return (
          <div>
            <h3 style={{ color: AppLightTheme.color.PRIMARY }}>Verify Code</h3>
          </div>
        );
      }}
      visible={show}
      style={{ width: "30vw" }}
      onHide={() => {
        handleClose();
      }}
    >
      <div>
        <div>
          <p style={{ color: AppLightTheme.color.PRIMARY, fontWeight: 600 }}>
            Email
          </p>
        </div>
        <div className="p-inputgroup my-3">
          <InputText value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div>
          <p style={{ color: AppLightTheme.color.PRIMARY, fontWeight: 600 }}>
            Verification code
          </p>
        </div>
        <div className="p-inputgroup my-3">
          <InputText value={code} onChange={(e) => setCode(e.target.value)} />
        </div>
        <div>
          <p style={{ color: AppLightTheme.color.PRIMARY, fontWeight: 600 }}>
            New Password
          </p>
        </div>
        <div className="p-inputgroup my-3">
          <InputText
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{ backgroundColor: AppLightTheme.color.PRIMARY }}
            label="submit"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Dialog>
  );
}
