import React, { useRef, useState } from "react";
import ChangePassword from "./ChangePassword";
import Navbar from "./Navbar";
import Spinner from "./Spinner";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useAppDispatch, useAppSelector } from "../../store";
import { userActions } from "../../features/user/userSlice";
import AlertBox from "./AlertBox";
import SideBar from "./SideBar";
import { AppLightTheme } from "../../utils/theme";

function Layout({ children }: { children: JSX.Element }) {
  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);
  const { auth } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const mainPage = useRef<HTMLDivElement>(null);
  const sideNav = useRef<HTMLDivElement>(null);
  const onLogoutClick = () => {
    confirmDialog({
      message: "Are you sure you want to log out?",
      header: "Logout",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        dispatch(userActions.signOut());
      },
    });
  };
  return (
    <div>
      {/* {showLogoutModal && (
        <div className="ui mini modal">
          <div className="ui icon header">
            <i className="privacy icon"></i> Are you sure you want to log out?
          </div>
          <div className="actions">
            <a
              onClick={(e) => {
                e.stopPropagation();
                setShowLogoutModal(false);
              }}
            >
              <div className="ui red cancel inverted button">
                <i className="remove icon"></i> No
              </div>
            </a>
            <a
              onClick={(e) => {
                e.stopPropagation();
                // logout
                setShowLogoutModal(false);
              }}
            >
              <div className="ui green ok inverted button">
                <i className="checkmark icon"></i> Yes
              </div>
            </a>
          </div>
        </div>
      )} */}
      <ConfirmDialog />
      <div style={{ backgroundColor: AppLightTheme.backgroundColor.PANEL }}>
        {auth?.loggedIn ? (
          <>
            <Navbar
              onLogoutNavClick={onLogoutClick}
              onChangePasswordClick={setChangePasswordModalVisible}
              onNavClick={() => {
                if (sideNav.current && mainPage.current) {
                  if (sideNav.current.style.width != "18.5%") {
                    sideNav.current.style.width = "18.5%";
                    mainPage.current.style.marginLeft = "18.5%";
                  } else {
                    sideNav.current.style.width = "0";
                    mainPage.current.style.marginLeft = "0";
                  }
                }
              }}
            />
            <ChangePassword
              visible={changePasswordModalVisible}
              onClose={setChangePasswordModalVisible}
            />
            <SideBar
              ref={sideNav}
              closeNav={() => {
                if (sideNav.current && mainPage.current) {
                  sideNav.current.style.width = "0";
                  mainPage.current.style.marginLeft = "0";
                }
              }}
            />
            <div
              id="main"
              ref={mainPage}
              style={{ marginTop: "2%", paddingTop: "4%" }}
            >
              {children}
            </div>
          </>
        ) : (
          <div
            id="main"
            ref={mainPage}
            // style={{ marginBottom: "2%", marginTop: "2%", paddingTop: "4%" }}
          >
            {children}
          </div>
        )}

        {/* <div style={{ visibility: "hidden" }}>Copyright@2023</div> */}
        <Spinner />
        <AlertBox />
      </div>
    </div>
  );
}

export default Layout;
