import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { useAppSelector } from "../../store";

export default function AlertBox() {
  const alert = useAppSelector((state) => state.alert);
  const toast = useRef<Toast>(null);
  useEffect(() => {
    if (alert.body === "null") return;
    toast.current?.show({
      severity: alert.variant,
      summary: alert.head,
      detail: alert.body,
      life: alert.timeout,
    });
  }, [alert]);
  return <Toast ref={toast} position="bottom-right" />;
}
