/* eslint-disable prettier/prettier */
import React from "react";
import { Link } from "react-router-dom";
import { ExternalPaths, NavigationPagePaths } from "../router/navigationPagePath";
import { useAppSelector } from "../store";
import { Card } from "primereact/card";
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router-dom';
import residentialLoanApplication from "../assets/documents/residentialLoanApplication.pdf";
import Call_tracking from "../../src/assets/images/Call_tracking.png"
import mortgage from "../../src/assets/images/mortgage.png"
import loanform from "../../src/assets/images/loanform.png"
import report from "../../src/assets/images/report.png"
const FeatureCard = ({ title, iconUrl, linkTo, description, show, href, display, id }: any) => {
	const navigate = useNavigate()

	const handleCardClick = () => {
		console.log("linkTo", linkTo)
		if (id == 4 || id == 5 || id == 6) {
			window.open(linkTo, "_blank");
		} else {
			navigate(linkTo);
		}
	};
	return (<>
		{show && (
			<div className="col-4">
				<div className="card p-1" style={{ width: "100%", cursor: "pointer" }} >
					<Card style={{ color: "#3376a3", maxHeight: "160px", minHeight: "160px", display: display }} onClick={() => handleCardClick()}>

						<div className="item  flex flex-column justify-content-center align-items-center" >
							<div className="ui tiny image" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<img src={iconUrl} alt={title} style={{ height: "60px", }} />
							</div>
							{/* <Divider layout="vertical" /> */}
							<div className="content">
								{href ?
									<a
										href={href}
										target="_blank"
										rel="noopener noreferrer"
										className="header font-semibold flex justify-content-center"
									>
										{title}
									</a> :
									<Link className="header font-semibold" to={linkTo} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
										{title}
									</Link>

								}

								<div className="meta text-black-alpha-90" style={{ marginTop: "5px" }}>
									<span>{description}</span>
								</div>

							</div>
						</div>

					</Card>
				</div>
			</div>)}</>
	);
};

export default function Home() {
	const { auth, userAttributes } = useAppSelector((state) => state.user);
	const showReMarketing =
		userAttributes && userAttributes["custom:ReMarketing"] === "1";
	const showResponderProfile =
		userAttributes && userAttributes["custom:ResponderProfile"] === "1";
	const showInMarketMonitoring =
		userAttributes && userAttributes["custom:InMarketMonitoring"] === "1";
	const showCamber = userAttributes && userAttributes["custom:Camber"] === "1";
	return (
		<div className="grid p-3">

			<FeatureCard
				title="Search Responder Profiles"
				iconUrl="https://img.icons8.com/color/96/000000/mailbox-closed-flag-down.png"
				linkTo={NavigationPagePaths.RESPONDER_PROFILES}
				description="Search and manage your large-scale direct mail leads."
				show={showResponderProfile}
				href=""
				id="1"
			/>


			<FeatureCard
				title="In-Market Monitoring"
				iconUrl="https://img.icons8.com/fluent/96/000000/shop.png"
				linkTo={NavigationPagePaths.IN_MARKET_MONITORING}
				description="Get a daily feed of prospects in the market NOW for a new mortgage."
				show={showInMarketMonitoring}
				href=''
				id="2"
			/>

			<FeatureCard
				title="ReMarketing"
				iconUrl="https://img.icons8.com/cotton/96/000000/fire-alarm.png"
				linkTo={NavigationPagePaths.RE_MARKETING}
				description="A daily feed of your past clients currently in the market for a new mortgage."
				show={showReMarketing}
				href=""
				id="3"
			/>

			<FeatureCard
				title="Campaign Call Tracking"
				iconUrl={Call_tracking}
				linkTo={ExternalPaths.CALL_CAMBERMARKETING}
				description="Open the Camber Call Tracking Website."
				show={true}
				href={ExternalPaths.CALL_CAMBERMARKETING}
				id="4"
			/>

			<FeatureCard
				title="Mortgage Calculator"
				iconUrl={mortgage}
				linkTo={ExternalPaths.MORTGAGE_CALCULATOR}
				description="Open up a Mortgage Calculator page powered by Calculator.net."
				show={true}
				href={ExternalPaths.MORTGAGE_CALCULATOR}
				id="5"
			/>

			<FeatureCard
				title="Loan Application (Form 1003)"
				iconUrl={loanform}
				linkTo={residentialLoanApplication}
				description="Start a Mortgage Application from an editable PDF that can be saved on your computer."
				show={true}
				href={residentialLoanApplication}
				id="6"
			/>

			<FeatureCard
				title="Reports"
				iconUrl={report}
				linkTo={NavigationPagePaths.CHART_REPORT}
				description=""
				show={showCamber}
				href=""
				id="7"
			/>

		</div>

	);
}