import React, { useState } from "react";
import { AppLightTheme } from "../utils/theme";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import AdminTable from "../components/ui/AdminTable";

export default function Admin() {
  const [searchKeyword, setSearchKeyword] = useState("");
  return (
    <div>
      <div
        className="w-full flex align-items-center justify-content-between flex-wrap card-container mb-2 px-4"
        style={{ color: AppLightTheme.backgroundColor.NAV_BAR }}
      >
        <h2 className="my-0 ml-2">
          <i className="fa-regular fa-user mx-2"></i>
          Admin
        </h2>
      </div>
      <Card className="m-4">
        <div className="flex-auto">
          <span className="p-input-icon-right w-full">
            <i
              className="fa-solid fa-magnifying-glass"
              style={{ color: AppLightTheme.color.PRIMARY }}
            />
            <InputText
              placeholder="Enter First Name, Last Name, Email Address to search..."
              className="w-full"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </span>
        </div>
      </Card>
      <AdminTable searchKeyword={searchKeyword} />
    </div>
  );
}
