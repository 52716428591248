import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import alertReducer from "./alert/alertSlice";
import recipientReducer from "./recipient/recipientSlice";
import inMarketReducer from "./inMarket/inMarketSlice";
import reMarketReducer from "./reMarket/reMarketSlice";
import spinnerReducer from "./spinner/spinnerSlice";

const rootReducer = combineReducers({
  user: userReducer,
  alert: alertReducer,
  spinner: spinnerReducer,
  recipient: recipientReducer,
  inMarket: inMarketReducer,
  reMarket: reMarketReducer,
});

export default rootReducer;
