import React, { useEffect, useState } from "react";

import { fetchReportData } from "../services/reportService";
import ReportChartView from "../components/reportChartview";
import Loader from "../components/loader";
import Spinner from "../components/ui/Spinner";
import { spinnerActions } from "../features/spinner/spinnerSlice";
import { useAppDispatch } from "../store";

const ChartReport: React.FC = () => {
  const [stateGraphData, setgraphData1] = useState([]);
  const [amountGraphData, setgraphData2] = useState([]);
  const [clientGraphData, setgraphData3] = useState([]);
  const [satusGraphData, setgraphData4] = useState([]);
  const [selectedOption, setSelectedOption] = useState("recipients");
  const dispatch = useAppDispatch();

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    fetchResult();
  }, [selectedOption]);

  const fetchResult = async () => {
    dispatch(spinnerActions.load({ message: "Loading....." }));
    const params = {
      tableName: selectedOption,
    };

    const promises: Promise<any>[] = [
      fetchReportData({ ...params, category: "state" }),
      fetchReportData({ ...params, category: "amount" }),
      fetchReportData({ ...params, category: "client" }),
      fetchReportData({ ...params, category: "status" }),
    ];

    try {
      const [resultState, resultAmount, resultClient, resultStatus] =
        await Promise.all(promises);

      setgraphData1(resultState);
      setgraphData2(resultAmount);
      setgraphData3(resultClient);
      setgraphData4(resultStatus);
      dispatch(spinnerActions.unload());
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <select
        id="Recipent"
        value={selectedOption}
        onChange={handleSelectChange}
        style={{
          position: "absolute",
          right: 25,
          background: "transparent",
          minWidth: 200,
          minHeight: 35,
          fontSize: 15,
          borderRadius: 5,
          borderColor: "grey",
          zIndex: 1,
          cursor: "pointer",
        }}
      >
        <option value="recipients">Search Responder</option>
        <option value="in_market_triggers">In Marketing</option>
        <option value="triggers">Re Marketing</option>
      </select>

      <ReportChartView
        heading={selectedOption}
        stateGraphData={stateGraphData}
        amountGraphData={amountGraphData}
        clientGraphData={clientGraphData}
        satusGraphData={satusGraphData}
        selectedOption={selectedOption}
      />
    </div>
  );
};

export default ChartReport;
