import React, { useEffect, useState, useRef } from "react";
import QueryForm, { IQueryFormHandlers } from "../components/form/QueryForm";
import { useAppDispatch, useAppSelector } from "../store";
import { recipientActions } from "../features/recipient/recipientSlice";
import Tabulation from "../components/ui/Tabulation";
import { alertActions } from "../features/alert/alertSlice";
import { TableNames } from "../constants/database";
import { AppLightTheme } from "../utils/theme";
// import { IoMdSearch } from "react-icons/io";
export default function Responder() {
  const pageLimit = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const recipientList = useAppSelector((state) => state.recipient);
  const [skipInitialRun, setSkipInitialRun] = useState(false);
  const formRef = useRef<IQueryFormHandlers>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    console.log(recipientList);
  }, [recipientList]);
  async function runQuery(
    term: string,
    // name: string,
    // address: string,
    // offerCode: string,
    loAssigned: string,
    status: string[] | null,
    startDate: Date | null,
    endDate: Date | null
  ) {
    if (
      (term && term.length >= 3) ||
      // (name && name.length >= 3) ||
      // (address && address.length >= 3) ||
      // (offerCode && offerCode.length >= 3) ||
      (loAssigned && loAssigned.length >= 2) ||
      (startDate && endDate) ||
      (status && status.length >= 1)
    ) {
      // console.log(currentPage);
      setSkipInitialRun(true);
      dispatch(
        recipientActions.fetchData({
          term: term.toUpperCase(),
          // name: name.toUpperCase(),
          // address: address.toUpperCase(),
          // offerCode: offerCode.toUpperCase(),
          loAssigned: loAssigned.toUpperCase(),
          startDate,
          endDate,
          status: status?.join(",") || "",
          pageLimit,
          currentPage,
        })
      );
    } else {
      dispatch(
        alertActions.show({
          body: "Please enter search criteria field values. ",
          variant: "error",
        })
      );
    }
    return "Success";
  }

  // useEffect(() => formRef.current?.submit(), [currentPage]);
  useEffect(() => {
    // Skip the initial render
    if (skipInitialRun) {
      formRef.current?.submit();
      setSkipInitialRun(true);
    }
  }, [currentPage]);
  useEffect(() => {
    return () => {
      dispatch(recipientActions.clear());
    };
  }, []);

  return (
    <div style={{ marginTop: "-2%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          marginLeft: "1.8%",
        }}
      >
        <div className="ui mini image">
          <img
            src="https://img.icons8.com/color/96/000000/mailbox-closed-flag-down.png"
            alt={"Search Responder Profiles"}
          />
        </div>
        <h2 style={{ color: AppLightTheme.color.PRIMARY, marginLeft: "0.8%" }}>
          Find A Recipient
        </h2>
      </div>
      <QueryForm type={"recipient"} ref={formRef} onRunQuery={runQuery} />
      <Tabulation
        type={"recipient"}
        data={recipientList}
        currentPage={currentPage}
        pageLimit={pageLimit}
        onPageChanged={(page) => {
          setCurrentPage(page);
        }}
        onExportCsv={() => {
          formRef.current?.downloadCsv(
            TableNames.RECIPIENTS,
            0, // pageLimit, by default we are sending 0 to get all records in csv file
            currentPage
          );
        }}
      />
    </div>
  );
}
