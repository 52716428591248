export const statusList = [
  { id: "Responded", name: "Responded" },
  {
    id: "Interested - Application Taken",
    name: "Interested - Application Taken",
  },
  {
    id: "Interested - Followup Required",
    name: "Interested - Followup Required",
  },
  { id: "Not Interested", name: "Not Interested" },
  { id: "Do Not Solicit", name: "Do Not Solicit" },
];
