import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAlert } from "../../interfaces/alert";
import { alertVariant } from "../../constants/alert";

const initialState: IAlert | null = {
  body: "null",
  variant: alertVariant.success,
  timeout: 1500,
};
export const alertsSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    show(state: IAlert, action: PayloadAction<IAlert>): IAlert {
      return { ...state, ...action.payload };
    },
  },
});

export const alertActions = { ...alertsSlice.actions };

export default alertsSlice.reducer;
