import React from "react";
import ReactEChart from "echarts-for-react";
const ClientBarChart = ({ clientGraphData, visible }: any) => {
  // let data = ["A", "B", "C", "D", "E"];
  console.log("clientGraphData", clientGraphData);
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },
    title: {
      // title of our chart
      text: "Client",
      textStyle: {
        color: "black",
      },
      left: "center",
      // itemStyle: { color: "red" },
    },
    toolbox: {
      show: true,
      feature: {
        mark: { show: true },
        magicType: { show: true, type: ["line", "bar"] },
        saveAsImage: { show: true },
      },
    },
    calculable: true,
    // legend: {
    //   data: ["Growth", "Budget 2011", "Budget 2012"],
    //   itemGap: 5,
    // },
    grid: {
      height: "80%",
      bottom: "2%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: clientGraphData?.[0]?.[0].map((i: any) => i.client_name),
      axisLabel: {
        interval: 0,
        // rotate: 45,
        // color: "white",
        // fontSize: "8",
        align: "right",
      },
    },

    yAxis: [
      {
        type: "value",
        // name: "Budget (million USD)",
        // axisLabel: {
        //   formatter: function (a) {
        //     a = +a;
        //     return isFinite(a) ? echarts.format.addCommas(+a / 1000) : "";
        //   },
        // },
      },
    ],
    dataZoom: [
      //   {
      //     show: true,
      //     start: 94,
      //     end: 100,
      //   },
      {
        type: "inside",
        start: 0,
        end: visible ? 100 : 5,
      },
      //   {
      //     show: true,
      //     yAxisIndex: 0,
      //     filterMode: "empty",
      //     width: 30,
      //     height: "80%",
      //     showDataShadow: false,
      //     left: "93%",
      //   },
    ],
    series: [
      {
        // name: "Budget 2011",
        type: "bar",
        data: clientGraphData?.[0]?.[0].map((i: any) => i.total),
      },
    ],
  };

  return (
    <ReactEChart style={{ height: "100%", width: "100%" }} option={option} />
  );
};

export default ClientBarChart;
