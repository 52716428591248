import {
  faAngleDown,
  faAngleUp,
  faL,
  faMagnifyingGlassPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import LineChart from "../charts/lineChart";
import Barchart from "../charts/barChart";
import PiChart from "../charts/piChart";
import ClientBarChart from "../charts/clientBarChart";
import { AppLightTheme } from "../../utils/theme";
import ChartViewModal from "../modal/chartViewModal";

const ReportChartView = ({
  stateGraphData,
  amountGraphData,
  clientGraphData,
  satusGraphData,
  selectedOption,
}: any) => {
  const [visibleLine, setVisibleLine] = useState<boolean>(false);
  const [visibleBar, setVisibleBar] = useState<boolean>(false);

  const [chartType, setChartType] = useState<string>("line");
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          minWidth: "100%",
          alignItems: "center",
          // padding: "5px",
          fontSize: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "row",
            whiteSpace: "nowrap",
            paddingLeft: 10,
          }}
        >
          <img
            src="https://img.icons8.com/color/96/000000/mailbox-closed-flag-down.png"
            alt={"Search Responder Profiles"}
            style={{ height: "14%", width: "14%" }}
          />

          <h2
            style={{ color: AppLightTheme.color.PRIMARY, marginLeft: "10px" }}
          >
            Reports
          </h2>
        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          style={{
            minHeight: 290,
            width: "50%",
            backgroundColor: "white",
            margin: "1% 1%",
            boxShadow: "10px 10px 10px  lightgray",
            position: "relative",
          }}
        >
          <div
            style={{ position: "absolute", bottom: 10, right: 10, zIndex: 1 }}
          >
            <FontAwesomeIcon
              onClick={() => {
                setVisibleLine(true);
                setChartType("line");
              }}
              icon={faMagnifyingGlassPlus}
              style={{ fontSize: 20, color: "grey", cursor: "pointer" }}
            />
          </div>
          <LineChart
            stateChartdata={stateGraphData}
            chart="line"
            visible={visibleLine}
          />
        </div>
        <div
          style={{
            minHeight: 290,
            width: "45%",
            backgroundColor: "white",
            margin: "1% 1%",
            boxShadow: "10px 10px 10px  lightgray",
          }}
        >
          <Barchart
            amountGraphData={amountGraphData}
            selectedOption={selectedOption}
          />
        </div>
        <div
          style={{
            minHeight: 290,
            width: "50%",
            boxShadow: "10px 10px 10px  lightgray",
            backgroundColor: "white",
            margin: "1% 1%",
            position: "relative",
          }}
        >
          <div
            style={{ position: "absolute", bottom: 10, right: 10, zIndex: 1 }}
          >
            <FontAwesomeIcon
              onClick={() => {
                setVisibleBar(true);
                setChartType("bar");
              }}
              icon={faMagnifyingGlassPlus}
              style={{ fontSize: 20, color: "grey", cursor: "pointer" }}
            />
          </div>
          <ClientBarChart
            clientGraphData={clientGraphData}
            chart="bar"
            visible={visibleBar}
          />
        </div>
        <div
          style={{
            minHeight: 290,
            width: "45%",
            backgroundColor: "white",
            margin: "1% 1%",
            boxShadow: "10px 10px 10px  lightgray",
          }}
        >
          <PiChart satusGraphData={satusGraphData} />
        </div>
      </div>
      <ChartViewModal
        visibleLine={visibleLine}
        visibleBar={visibleBar}
        setVisibleLine={setVisibleLine}
        setVisibleBar={setVisibleBar}
        stateChartdata={stateGraphData}
        clientGraphData={clientGraphData}
        type={chartType}
      />
    </div>
  );
};

export default ReportChartView;
