import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import CognitoService from "../../services/cognitoService";
import { useAppDispatch } from "../../store";
import { spinnerActions } from "../../features/spinner/spinnerSlice";
import { alertActions } from "../../features/alert/alertSlice";
import { CognitoIdentityServiceProvider } from "aws-sdk";

export default function EditUserForm({
  visible,
  onClose,
  onUserEdited,
  UserDetails,
}: {
  visible: boolean;
  onClose(value: boolean): void;
  onUserEdited(): void;
  UserDetails: CognitoIdentityServiceProvider.UserType;
}) {
  const [admin, setAdmin] = useState(false);
  const [clientName, setClientName] = useState("");
  const [branches, setBranches] = useState("");
  const [responderprofile, setResponderprofile] = useState(false);
  const [inmarketmonitoring, setInmarketmonitoring] = useState(false);
  const [remarketing, setRemarketing] = useState(false);
  const [formVisible, setFormVisible] = useState(visible);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setFormVisible(visible);
  }, [visible]);

  useEffect(() => {
    const Attribute = UserDetails.Attributes;
    if (Attribute) {
      const clientNameAttribute = Attribute.find(
        (attr) => attr.Name === "custom:client_name"
      );
      const branchAttribute = Attribute.find(
        (attr) => attr.Name === "custom:branches"
      );
      const responderprofileAttribute = Attribute.find(
        (attr) => attr.Name === "custom:ResponderProfile"
      );
      const inmarketmonitoringAttribute = Attribute.find(
        (attr) => attr.Name === "custom:InMarketMonitoring"
      );
      const remarketingAttribute = Attribute.find(
        (attr) => attr.Name === "custom:ReMarketing"
      );
      const adminAttribute = Attribute.find(
        (attr) => attr.Name === "custom:Camber"
      );

      setClientName(clientNameAttribute?.Value || "");
      setBranches(branchAttribute?.Value || "");
      setResponderprofile(responderprofileAttribute?.Value === "1");
      setInmarketmonitoring(inmarketmonitoringAttribute?.Value === "1");
      setRemarketing(remarketingAttribute?.Value === "1");
      setAdmin(adminAttribute?.Value === "1");
    }
  }, [UserDetails]);

  useEffect(() => {
    if (admin) {
      setResponderprofile(true);
      setInmarketmonitoring(true);
      setRemarketing(true);
    }
  }, [admin]);

  function editUser() {
    const UserAttributes: CognitoIdentityServiceProvider.AttributeType[] = [
      {
        Name: "custom:client_name",
        Value: clientName ? clientName : "",
      },
      {
        Name: "custom:branches",
        Value: branches ? (branches as string) : "",
      },
      {
        Name: "custom:Camber",
        Value: admin == true ? "1" : "0",
      },
      {
        Name: "custom:ReMarketing",
        Value: remarketing == true ? "1" : "0",
      },
      {
        Name: "custom:ResponderProfile",
        Value: responderprofile == true ? "1" : "0",
      },
      {
        Name: "custom:InMarketMonitoring",
        Value: inmarketmonitoring == true ? "1" : "0",
      },
    ];

    const { Username, Attributes } = UserDetails;
    if (!Username || !Attributes) {
      return;
    }
    dispatch(spinnerActions.load({ message: "Updating User..." }));
    CognitoService.updateUserAttributes({
      Username: Username,
      Attributes: UserAttributes,
    })
      .then(() => {
        onUserEdited();
        dispatch(spinnerActions.unload());
        setFormVisible(false);
        onClose(false);
        dispatch(
          alertActions.show({
            body: "User updated Successfully",
            variant: "success",
          })
        );
      })
      .catch((e: Error) => {
        setFormVisible(false);
        onClose(false);
        dispatch(spinnerActions.unload());
        dispatch(alertActions.show({ body: e.message, variant: "error" }));
      });
  }

  const footer = (
    <div className="actions">
      <div className="ui black deny button" onClick={() => onClose(false)}>
        Cancel
      </div>
      <div
        id="saveWatchlistModal"
        className="ui positive button"
        onClick={editUser}
      >
        Save
      </div>
    </div>
  );

  return (
    <Dialog
      visible={formVisible}
      onHide={() => onClose(false)}
      header={<div className="header">Edit User</div>}
      footer={footer}
      style={{ width: "41vw" }}
    >
      <form name="editUser" className="ui form">
        <div className="two fields">
          <div className="field">
            <label>Client Name:</label>
            <input
              name="clientName"
              type="text"
              placeholder="Enter Client Name..."
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            />
          </div>
          <div className="field">
            <label>Branches:</label>
            <input
              name="branches"
              type="text"
              placeholder="Enter Branches..."
              value={branches}
              onChange={(e) => setBranches(e.target.value)}
            />
          </div>
        </div>
        <div className="four fields">
          <div className="field">
            <div className="ui checkbox">
              <input
                name="admin"
                type="checkbox"
                checked={admin}
                onChange={() => setAdmin((p) => !p)}
              />
              <label>Camber Admin?</label>
            </div>
          </div>
          <div className="field">
            <div className="ui checkbox">
              <input
                name="responderProfile"
                type="checkbox"
                disabled={admin}
                checked={responderprofile}
                onChange={() => setResponderprofile((p) => !p)}
              />
              <label>Responder Profile?</label>
            </div>
          </div>
          <div className="field">
            <div className="ui checkbox">
              <input
                name="inMarket"
                type="checkbox"
                disabled={admin}
                checked={inmarketmonitoring}
                onChange={() => setInmarketmonitoring((p) => !p)}
              />
              <label>In-Market Monitoring?</label>
            </div>
          </div>
          <div className="field">
            <div className="ui checkbox">
              <input
                name="admin"
                type="checkbox"
                disabled={admin}
                checked={remarketing}
                onChange={() => setRemarketing((p) => !p)}
              />
              <label>ReMarketing?</label>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
}
