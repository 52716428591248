import React from "react";
import { IRecipient } from "../../interfaces/recipient";
import { ITrigger } from "../../interfaces/trigger";
import { AppLightTheme } from "../../utils/theme";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import moment from "moment";
import { Button } from "primereact/button";

export default function OfferForm({
  type,
  data,
  setData,
  onCancel,
  onUpdate,
}:
  | {
      type: "recipient";
      data?: Partial<IRecipient>;
      setData: React.Dispatch<
        React.SetStateAction<Partial<IRecipient> | undefined>
      >;
      onCancel(): void;
      onUpdate(): void;
    }
  | {
      type: "trigger";
      data?: Partial<ITrigger>;
      setData: React.Dispatch<
        React.SetStateAction<Partial<ITrigger> | undefined>
      >;
      onCancel(): void;
      onUpdate(): void;
    }) {
  const header = (
    <div
      className="w-full flex align-items-center justify-content-left flex-wrap card-container mb-2"
      style={{ color: AppLightTheme.backgroundColor.NAV_BAR }}
    >
      <h3 className="my-0 mr-2">Offer Information</h3>
      <div className="flex-auto">
        <hr style={{ border: "1px solid" }} />
      </div>
    </div>
  );
  return (
    <Card className="m-4">
      {header}
      {type === "recipient" ? (
        <>
          <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 py-2">
            <div className="flex-auto">
              <label htmlFor="mtgamt" className="font-bold block mb-2">
                Mortage Amount
              </label>
              <InputText
                className="w-full"
                id="mtgamt"
                disabled
                value={
                  data?.MTGAMT
                    ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(data?.MTGAMT)
                    : ""
                }
              />
            </div>
            <div className="flex-auto">
              <label htmlFor="ltv" className="font-bold block mb-2">
                LTV
              </label>
              <InputText
                className="w-full"
                id="ltv"
                disabled
                value={data?.LTV || ""}
              />
            </div>
            <div className="flex-auto">
              <label htmlFor="mod" className="font-bold block mb-2">
                Mortage Origination Date
              </label>
              <InputText
                className="w-full"
                id="mod"
                disabled
                value={data?.PROPERDATE || ""}
              />
            </div>
          </div>
          <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 py-2">
            <div className="flex-auto">
              <label htmlFor="lender" className="font-bold block mb-2">
                Lender Name
              </label>
              <InputText
                className="w-full"
                id="lender"
                disabled
                value={data?.LENDER || ""}
              />
            </div>
            <div className="flex-auto">
              <label htmlFor="loantype" className="font-bold block mb-2">
                Loan/Data Type
              </label>
              <InputText
                className="w-full"
                id="loantype"
                disabled
                value={data?.TYPE || ""}
              />
            </div>
            <div className="flex-auto">
              <label htmlFor="letter" className="font-bold block mb-2">
                Letter Received
              </label>
              <InputText
                className="w-full"
                id="letter"
                disabled
                value={data?.DESIGN || ""}
              />
            </div>
          </div>
          <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 py-2">
            <div className="flex-auto">
              <label htmlFor="ocode" className="font-bold block mb-2">
                Offer Code
              </label>
              <InputText
                className="w-full"
                id="ocode"
                disabled
                value={data?.OFFER_CODE || ""}
              />
            </div>
            <div className="flex-auto">
              <label htmlFor="rdebt" className="font-bold block mb-2">
                Rev Debt
              </label>
              <InputText
                className="w-full"
                id="rdebt"
                disabled
                value={data?.REVDEBT?.toString()}
              />
            </div>
            <div className="flex-auto">
              <label htmlFor="maildate" className="font-bold block mb-2">
                Mail Date
              </label>
              <InputText
                className="w-full"
                id="maildate"
                disabled
                value={
                  data?.MAIL_DATE
                    ? moment(data?.MAIL_DATE).format("MM/DD/YYYY")
                    : ""
                }
              />
            </div>
          </div>
          <div className="w-full flex align-items-center justify-content-left flex-wrap card-container  gap-4 py-2">
            <div className="w-8">
              <label htmlFor="notes" className="font-bold block mb-2">
                Notes
              </label>
              <InputTextarea
                className="w-full"
                id="notes"
                rows={3}
                value={data?.notes || ""}
                onChange={(e) => {
                  setData((p) => ({ ...p, notes: e.target.value }));
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 py-2">
            <div className="flex-auto">
              <label htmlFor="ocode" className="font-bold block mb-2">
                Offer Code
              </label>
              <InputText
                className="w-full"
                id="ocode"
                disabled
                value={data?.OFFER_CODE || ""}
              />
            </div>
            <div className="flex-auto">
              <label htmlFor="maildate" className="font-bold block mb-2">
                Mail Date
              </label>
              <InputText
                className="w-full"
                id="maildate"
                disabled
                value={
                  data?.MAIL_DATE
                    ? moment(data?.MAIL_DATE).format("MM/DD/YYYY")
                    : ""
                }
              />
            </div>
            <div className="flex-auto">
              <label htmlFor="tdate" className="font-bold block mb-2">
                Trigger Date
              </label>
              <InputText
                className="w-full"
                id="tdate"
                disabled
                value={data?.TRIGGER_DATE?.toString()}
              />
            </div>
          </div>
          <div className="w-8 flex align-items-center justify-content-center flex-wrap card-container  gap-4 py-2">
            <div className="flex-auto">
              <label htmlFor="eod" className="font-bold block mb-2">
                Expiration Date
              </label>
              <InputText
                className="w-full"
                id="eod"
                disabled
                value={data?.EXPIRATION || ""}
              />
            </div>
            <div className="flex-auto">
              <label htmlFor="mtgamt" className="font-bold block mb-2">
                Mortage Amount
              </label>
              <InputText
                className="w-full"
                id="mtgamt"
                disabled
                value={
                  data?.MTGAMT
                    ? new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(data?.MTGAMT)
                    : ""
                }
              />
            </div>
          </div>
          <div className="w-full flex align-items-center justify-content-left flex-wrap card-container  gap-4 py-2">
            <div className="w-8">
              <label htmlFor="notes" className="font-bold block mb-2">
                Notes
              </label>
              <InputTextarea
                className="w-full"
                id="notes"
                rows={3}
                value={data?.notes || ""}
                onChange={(e) => {
                  setData((p) => ({ ...p, notes: e.target.value }));
                }}
              />
            </div>
          </div>
        </>
      )}
      <div className="py-2">
        <Button
          label="Cancel"
          onClick={onCancel}
          outlined
          style={{
            color: AppLightTheme.color.PRIMARY,
          }}
        />{" "}
        <Button
          label={"Update Changes"}
          onClick={onUpdate}
          style={{
            backgroundColor: AppLightTheme.color.PRIMARY,
            borderColor: AppLightTheme.borderColor.PRIMARY,
          }}
        />
      </div>
    </Card>
  );
}
