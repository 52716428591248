import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IQueryResponse } from "../../interfaces/response";
import { IQueryTrigger } from "../../interfaces/trigger";
import { spinnerActions } from "../spinner/spinnerSlice";
import SearchService from "../../services/searchService";
import { alertActions } from "../alert/alertSlice";
import { alertVariant } from "../../constants/alert";
import { TableNames } from "../../constants/database";

interface ITriggerPayload {
  term: string;
  // name: string;
  // address: string;
  // offerCode: string;
  loAssigned: string;
  startDate: Date | null;
  endDate: Date | null;
  status: string;
  pageLimit: number;
  currentPage: number;
}
const initialState: IQueryResponse<IQueryTrigger> = [[], [{ count: 0 }]];
const fetchData = createAsyncThunk(
  "reMarket/fetch",
  async (
    {
      term = "",
      // name = "",
      // address = "",
      // offerCode = "",
      loAssigned = "",
      status = "",
      startDate,
      endDate,
      pageLimit,
      currentPage,
    }: ITriggerPayload,
    { dispatch }
  ) => {
    try {
      dispatch(spinnerActions.load({ message: "Requesting Data..." }));
      const results = (await SearchService.queryUserRecipientsByNameLOCriteria(
        "getremarketingtriggers",
        term,
        // name,
        // address,
        // offerCode,
        loAssigned,
        startDate,
        endDate,
        TableNames.TRIGGERS,
        status,
        pageLimit,
        currentPage
      )) as IQueryResponse<IQueryTrigger>;
      dispatch(spinnerActions.unload());
      return results as IQueryResponse<IQueryTrigger>;
    } catch (e) {
      if (e instanceof Error) {
        dispatch(spinnerActions.unload());
        dispatch(
          alertActions.show({
            variant: alertVariant.error,
            body: e.message || "Something is wrong",
          })
        );
        throw e;
      }
    }
  }
);
export const reMarketSlice = createSlice({
  name: "reMarket",
  initialState,
  reducers: {
    load(
      state: IQueryResponse<IQueryTrigger>,
      action: PayloadAction<IQueryResponse<IQueryTrigger>>
    ): IQueryResponse<IQueryTrigger> {
      return action.payload;
    },
    clear(): IQueryResponse<IQueryTrigger> {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      if (!action.payload) return state;
      state = structuredClone
        ? structuredClone(action.payload)
        : { ...action.payload };
      return state;
    });
  },
});

export const reMarketActions = { ...reMarketSlice.actions, fetchData };

export default reMarketSlice.reducer;
