import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Button } from "primereact/button";
import SearchService from "../../services/searchService";
import { useAppDispatch } from "../../store";
import { spinnerActions } from "../../features/spinner/spinnerSlice";
import { alertActions } from "../../features/alert/alertSlice";
import { statusList } from "../../constants";
import { AppLightTheme } from "../../utils/theme";

import { Checkbox } from "primereact/checkbox";
import "./style.css";
interface IQueryFormParams {
  type: "recipient" | "trigger";
  onRunQuery: (
    term: string,
    // name: string,
    // address: string,
    // offerCode: string,
    loAssigned: string,
    status: string[] | null,
    startDate: Date | null,
    endDate: Date | null
  ) => Promise<"Success" | string>;
}
export interface IQueryFormHandlers {
  submit: () => void;
  downloadCsv: (table: string, limit: number, pageNumber: number) => void;
}
const QueryForm = forwardRef<IQueryFormHandlers, IQueryFormParams>(
  ({ type, onRunQuery }, ref) => {
    const [term, setTerm] = useState("");
    // const [name, setName] = useState("");
    // const [address, setAddress] = useState("");
    // const [offerCode, setOfferCode] = useState("");
    const [loAssigned, setLoAssigned] = useState("");
    const [selectedStatusList, setSelectedStatusList] = useState<
      typeof statusList | null
    >(null);
    const [startDate, setStartDate] = useState<string | Date | Date[] | null>(
      null
    );
    const [endDate, setEndDate] = useState<string | Date | Date[] | null>(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();

    const load = () => {
      if ((!startDate && endDate) || (startDate && !endDate)) {
        dispatch(
          alertActions.show({
            body: "Please select both start date and end date",
            variant: "error",
          })
        );

        return;
      }
      setLoading(true);

      onRunQuery(
        // name,
        // address,
        // offerCode,
        term,
        loAssigned,
        selectedStatusList?.map((v) => v.name) || null,
        startDate as Date | null,
        endDate as Date | null
      )
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
      // todo error alerting
    };
    const panelFooterTemplate = () => {
      const length = selectedStatusList ? selectedStatusList.length : 0;
      return (
        <div className="py-2 px-3">
          <b>{length}</b> item{length > 1 ? "s" : ""} selected.
        </div>
      );
    };
    const panelHeaderTemplate = () => {
      const allSelected = selectedStatusList?.length === statusList.length;

      return (
        <div
          style={{
            paddingLeft: "18px",
            paddingTop: "15px",
            paddingBottom: "15px",
            borderBottom: "1px solid #A5A9AF",
          }}
        >
          <Checkbox
            checked={allSelected}
            onChange={(e) => {
              setSelectedStatusList(e.checked ? statusList : null);
            }}
          />
          <label className="ml-2">
            {!allSelected ? "Select All" : "Deselect All"}
          </label>
        </div>
      );
    };

    useImperativeHandle(ref, () => ({
      // Only expose submit and nothing else
      submit: load,
      downloadCsv(table, limit, pageNumber) {
        dispatch(spinnerActions.load({ message: "Exporting Data..." }));
        if (type === "recipient") {
          SearchService.downloadCsv(
            "responders",
            table,
            // name,
            // address,
            // offerCode,
            term,
            loAssigned,
            selectedStatusList?.map((v) => v.name).join(",") || "",
            startDate as Date,
            endDate as Date,
            limit,
            pageNumber
          )
            .then(() => dispatch(spinnerActions.unload()))
            .catch((e: Error) => {
              dispatch(spinnerActions.unload());
              dispatch(
                alertActions.show({ body: e.message, variant: "error" })
              );
            });
        } else {
          SearchService.downloadCsv(
            "getremarketingtriggers",
            table,
            // name,
            // address,
            // offerCode,
            term,
            loAssigned,
            selectedStatusList?.map((v) => v.name).join(",") || "",
            startDate as Date,
            endDate as Date,
            limit,
            pageNumber
          )
            .then(() => dispatch(spinnerActions.unload()))
            .catch((e: Error) => {
              dispatch(spinnerActions.unload());
              dispatch(
                alertActions.show({ body: e.message, variant: "error" })
              );
            });
        }
      },
    }));
    return (
      <Card className="m-4">
        <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 p-4">
          {/* <div className="flex-auto" style={{ width: "calc(30% - 8px)" }}>
            <h4>Search by Name</h4>
            <span className="p-input-icon-right w-full">
              {name ? (
                <i
                  className="pi pi-times"
                  style={{ fontWeight: 1000, cursor: "pointer" }}
                  onClick={() => setName("")}
                />
              ) : (
                <i
                  className="pi pi-search"
                  style={{ fontWeight: 1000, cursor: "pointer" }}
                />
              )}
              <InputText
                className="w-full"
                placeholder="Enter name to search..."
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </span>
          </div>
          <div className="flex-auto" style={{ width: "calc(30% - 8px)" }}>
            <h4>Search by Address</h4>
            <span className="p-input-icon-right w-full">
              {address ? (
                <i
                  className="pi pi-times"
                  style={{ fontWeight: 1000, cursor: "pointer" }}
                  onClick={() => setAddress("")}
                />
              ) : (
                <i
                  className="pi pi-search"
                  style={{ fontWeight: 1000, cursor: "pointer" }}
                />
              )}
              <InputText
                className="w-full"
                placeholder="Enter address to search..."
                id="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </span>
          </div>
          <div className="flex-auto" style={{ width: "calc(30% - 8px)" }}>
            <h4>Search by Offer Code</h4>
            <span className="p-input-icon-right w-full">
              {offerCode ? (
                <i
                  className="pi pi-times"
                  style={{ fontWeight: 1000, cursor: "pointer" }}
                  onClick={() => setOfferCode("")}
                />
              ) : (
                <i
                  className="pi pi-search"
                  style={{ fontWeight: 1000, cursor: "pointer" }}
                />
              )}
              <InputText
                className="w-full"
                placeholder="Enter offer code to search..."
                id="offerCode"
                value={offerCode}
                onChange={(e) => setOfferCode(e.target.value)}
              />
            </span>
          </div> */}
          <div className="flex-auto">
            <h4>Search by Name, Address, Offer Code</h4>
            <span className="p-input-icon-right w-full">
              {term ? (
                <i
                  className="pi pi-times"
                  style={{ fontWeight: 1000, cursor: "pointer" }}
                  onClick={() => setTerm("")}
                />
              ) : (
                <i
                  className="pi pi-search"
                  style={{ fontWeight: 1000, cursor: "pointer" }}
                />
              )}
              <InputText
                className="w-full"
                placeholder="Enter first name,last name,offer code or address to search..."
                id="term"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
              />
            </span>
          </div>
          <div className="flex-auto">
            <h4>Search by LO Assigned</h4>
            <span className="p-input-icon-right w-full">
              {loAssigned ? (
                <i
                  className="pi pi-times"
                  style={{ fontWeight: 1000, cursor: "pointer" }}
                  onClick={() => setLoAssigned("")}
                />
              ) : (
                <i
                  className="pi pi-search"
                  style={{ fontWeight: 1000, cursor: "pointer" }}
                />
              )}
              <InputText
                className="w-full"
                placeholder="Enter name of LO Assigned to search..."
                id="lo_assigned"
                value={loAssigned}
                onChange={(e) => setLoAssigned(e.target.value)}
              />
            </span>
          </div>
          <div style={{ width: "32.2%" }}>
            <h4>Search by Status</h4>
            <div className="card flex justify-content-center">
              <MultiSelect
                value={selectedStatusList}
                options={statusList}
                onChange={(e) => setSelectedStatusList(e.value)}
                optionLabel="name"
                placeholder="Select"
                panelHeaderTemplate={panelHeaderTemplate}
                panelFooterTemplate={panelFooterTemplate}
                className="w-full"
                display="chip"
              />
            </div>
          </div>
        </div>
        <hr className="mx-4" />
        <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 pt-4 px-4">
          <h4 className="flex-auto">
            {type === "recipient" ? "Mail Date Range" : "Trigger Date Range"}
          </h4>
          <div className="flex-auto"></div>
          <div className="flex-auto"></div>
        </div>
        <div className="w-full flex align-items-center justify-content-center flex-wrap card-container  gap-4 px-4 pt-2 pb-4">
          <div className="flex-auto">
            <div
              className="card flex justify-content-center"
              style={{ backgroundColor: "white" }}
            >
              <Calendar
                className="w-full"
                value={startDate}
                onChange={(e: CalendarChangeEvent) =>
                  e.value && setStartDate(e.value)
                }
                showIcon
                placeholder="Start Date"
              />
            </div>
          </div>
          <div className="flex-auto">
            <div className="card flex justify-content-center">
              <Calendar
                className="w-full"
                value={endDate}
                onChange={(e: CalendarChangeEvent) =>
                  e.value && setEndDate(e.value)
                }
                showIcon
                placeholder="End Date"
              />
            </div>
          </div>
          <div className="flex-auto">
            <div className="card flex justify-content-end gap-2">
              <Button
                label="Clear Dates"
                severity="danger"
                outlined
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                }}
              />
              <Button
                label="Clear Status"
                severity="danger"
                outlined
                onClick={() => {
                  setSelectedStatusList(null);
                }}
              />
              <Button
                label="Clear Lo Assigned"
                severity="danger"
                outlined
                onClick={() => {
                  setLoAssigned("");
                }}
              />
              <Button
                label="Search"
                icon="pi pi-search"
                loading={loading}
                style={{
                  color: "white",
                  backgroundColor: AppLightTheme.color.PRIMARY,
                  borderColor: AppLightTheme.borderColor.PRIMARY,
                }}
                onClick={load}
              />
            </div>
          </div>
        </div>
      </Card>
    );
  }
);
QueryForm.displayName = "QueryForm";

export default QueryForm;
