export const AppLightTheme = {
  color: {
    PRIMARY: "#008db1",
    DELETE: "#F22",
    DANGER: "#e61610",
    TABLE_HEADER: "#000",
    USER_ICON: "#006e8c",
    PAGINATION: "#428ac9",
    RESET_PASSWORD: "#d68227",
  },
  backgroundColor: {
    PRIMARY: "#FFF",
    PANEL: "#f1f7ff",
    TABLE_HEADER: "#e5e5e5",
    NAV_BAR: "#3377a3",
    USER_ICON: "#99bbd1",
    NAV_ACTIVE: "#004e63",
  },
  borderColor: {
    PRIMARY: "#006e8c",
    TABLE: "#d8d8d8",
  },
} as const;
