import ReactEChart from "echarts-for-react";
export default function LineChart({ stateChartdata, visible }: any) {
  console.log("lineChartdata", stateChartdata);
  const option = {
    title: [
      {
        left: "center",
        text: "State",
      },
    ],
    tooltip: {
      trigger: "item",
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
        mark: { show: true },
        magicType: { show: true, type: ["line", "bar"] },
      },
    },
    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: visible ? 100 : 5,
      },
      // {
      //   type: "slider",
      // },
    ],
    legend: {
      show: true,
      vertical: false,
      icon: "rect",
      orient: "horizontal",
      itemWidth: 15,
      lineStyle: {
        type: "solid",
      },
      textStyle: {
        color: "#c0c0c0",
      },
      bottom: 5,
    },
    grid: {
      height: "80%",
      bottom: "2%",
      containLabel: true,
    },
    xAxis: {
      data: stateChartdata?.[0]?.[0].map((i: any) => i.state),
      type: "category",
      // show: false,
      boundaryGap: false,
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "black",
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        interval: 0,
        rotate: 45,
        // color: "white",
        fontSize: "8",
        align: "right",
      },
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "line",
        name: "",
        smooth: true,
        data: stateChartdata?.[0]?.[0].map((i: any) => i.total),
      },
    ],
  };
  return (
    <ReactEChart style={{ height: "100%", width: "100%" }} option={option} />
  );
}
