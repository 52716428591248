import React, { useState, useEffect } from "react";
import { AppLightTheme } from "../../utils/theme";
import EditUserForm from "../form/EditUserForm";
import { useAppDispatch, useAppSelector } from "../../store";
import { userActions } from "../../features/user/userSlice";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import { Dialog } from "primereact/dialog";
import { spinnerActions } from "../../features/spinner/spinnerSlice";
import CognitoService from "../../services/cognitoService";
import { alertActions } from "../../features/alert/alertSlice";

export default function Action({
  UserDetails,
}: {
  UserDetails: CognitoIdentityServiceProvider.UserType;
}) {
  const [editUserModal, setEditUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [resetUserModal, setResetUserModal] = useState(false);
  const dispatch = useAppDispatch();

  const onClose = (value: boolean) => {
    setDeleteUserModal(false);
    setResetUserModal(false);
  };

  const onDeleteButtonClick = () => {
    const { Username, Attributes } = UserDetails;
    if (!Username || !Attributes) {
      return;
    }
    dispatch(spinnerActions.load({ message: "Deleting User..." }));
    CognitoService.deleteUser(Username)
      .then(() => {
        setDeleteUserModal(false);
        onClose(false);
        dispatch(spinnerActions.unload());
        dispatch(userActions.listUsers());
        dispatch(
          alertActions.show({
            body: "User deleted Successfully",
            variant: "success",
          })
        );
      })
      .catch((e: Error) => {
        dispatch(spinnerActions.unload());
        dispatch(alertActions.show({ body: e.message, variant: "error" }));
        setDeleteUserModal(false);
        onClose(false);
      });
  };

  const onResetButtonClick = () => {
    const { Username, Attributes } = UserDetails;
    if (!Username || !Attributes) {
      return;
    }
    dispatch(spinnerActions.load({ message: "Resetting User..." }));
    CognitoService.forgotPassword(Username)
      .then(() => {
        setResetUserModal(false);
        onClose(false);
        dispatch(spinnerActions.unload());
        dispatch(
          alertActions.show({
            body: "User reseted Successfully",
            variant: "success",
          })
        );
      })
      .catch((e: Error) => {
        setResetUserModal(false);
        onClose(false);
        dispatch(spinnerActions.unload());
        dispatch(alertActions.show({ body: e.message, variant: "error" }));
      });
  };
  const footer = (
    <div className="actions">
      <div
        className="ui black deny button"
        onClick={() => {
          onClose(false);
        }}
      >
        No
      </div>
      <div
        id="saveWatchlistModal"
        className="ui positive button"
        onClick={onDeleteButtonClick}
      >
        Yes
      </div>
    </div>
  );
  const footerReset = (
    <div className="actions">
      <div
        className="ui black deny button"
        onClick={() => {
          onClose(false);
        }}
      >
        No
      </div>
      <div
        id="saveWatchlistModal"
        className="ui positive button"
        onClick={onResetButtonClick}
      >
        Yes
      </div>
    </div>
  );

  return (
    <div className="flex justify-content-center flex-wrap card-container gap-2">
      <div className="user-action" data-tooltip="Edit">
        <i
          className="fa fa-light fa-pencil user-action "
          onClick={() => setEditUserModal(true)}
          style={{ backgroundColor: AppLightTheme.color.PAGINATION }}
        ></i>
      </div>
      <div className="user-action" data-tooltip="Reset Password">
        <i
          className="fa fa-light fa-arrows-rotate user-action"
          style={{ backgroundColor: AppLightTheme.color.RESET_PASSWORD }}
          onClick={() => setResetUserModal(true)}
        ></i>
      </div>
      <div className="user-action" data-tooltip="Delete User">
        <i
          className="fa fa-regular fa-trash-can user-action"
          style={{ backgroundColor: AppLightTheme.color.DANGER }}
          onClick={() => setDeleteUserModal(true)}
        ></i>
      </div>
      <EditUserForm
        visible={editUserModal}
        onClose={setEditUserModal}
        onUserEdited={() => dispatch(userActions.listUsers())}
        UserDetails={UserDetails}
      />
      {/* <!-- Reset Modal Start--> */}
      <Dialog
        visible={resetUserModal}
        onHide={() => onClose(false)}
        header={
          <div className="header">
            Are you sure you want to reset this user?
          </div>
        }
        footer={footerReset}
        style={{ width: "41vw" }}
      ></Dialog>
      {/* <!--Reset Modal end--> */}
      {/* <!-- Delete Modal Start--> */}
      <Dialog
        visible={deleteUserModal}
        onHide={() => onClose(false)}
        header={
          <div className="header">
            Are you sure you want to delete this user?
          </div>
        }
        footer={footer}
        style={{ width: "41vw" }}
      ></Dialog>
      {/* <!--Delete Modal end--> */}
    </div>
  );
}
