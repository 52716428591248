export function makeid(length: number) {
  const result: string[] = [];
  // Mandatory letters to be used (total 4 conditions)
  const upperCaseMandatoryLetter = "HJKLMNPQ".charAt(
    Math.floor(Math.random() * 8)
  );
  const lowerCaseMandatoryLetter = "abcdefgz".charAt(
    Math.floor(Math.random() * 8)
  );
  const numberMandatoryLetter = "12345678".charAt(
    Math.floor(Math.random() * 8)
  );
  const specialMandatoryLetter = "!@$%&*[}".charAt(
    Math.floor(Math.random() * 8)
  );
  const characters = "ABCDEFG!@$%&*rstuvwxy@!z0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length - 4; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  let finalResult = result.join("");
  const positionArray: number[] = [];
  while (positionArray.length < 4) {
    const randomPosition = Math.floor(Math.random() * length);
    if (positionArray.indexOf(randomPosition) == -1) {
      positionArray.push(randomPosition);
    }
  }
  finalResult = generateRandomPosition(
    finalResult,
    upperCaseMandatoryLetter,
    positionArray[0]
  );
  finalResult = generateRandomPosition(
    finalResult,
    lowerCaseMandatoryLetter,
    positionArray[1]
  );
  finalResult = generateRandomPosition(
    finalResult,
    numberMandatoryLetter,
    positionArray[2]
  );
  finalResult = generateRandomPosition(
    finalResult,
    specialMandatoryLetter,
    positionArray[3]
  );
  return finalResult;
}
export function generateRandomPosition(
  generatedPassword: string,
  newText: string,
  newTextPosition: number
) {
  // const a = "I want apple";
  // const b = " an";
  // const position = 6;
  const updatedPassword = [
    generatedPassword.slice(0, newTextPosition),
    newText,
    generatedPassword.slice(newTextPosition),
  ].join("");
  return updatedPassword;
}
/**
 * Given the position in the sequence of pagination links [i], figure out what page number corresponds to that position.
 *
 * @param i
 * @param currentPage
 * @param paginationRange
 * @param totalPages
 * @returns {*}
 */
function calculatePageNumber(
  i: number,
  currentPage: number,
  paginationRange: number,
  totalPages: number
) {
  const halfWay = Math.ceil(paginationRange / 2);
  if (i === paginationRange) {
    return totalPages;
  } else if (i === 1) {
    return i;
  } else if (paginationRange < totalPages) {
    if (totalPages - halfWay < currentPage) {
      return totalPages - paginationRange + i;
    } else if (halfWay < currentPage) {
      return currentPage - halfWay + i;
    } else {
      return i;
    }
  } else {
    return i;
  }
}
/**
 * Generate an array of page numbers (or the '...' string) which is used in an ng-repeat to generate the
 * links used in pagination
 *
 * @param currentPage
 * @param rowsPerPage
 * @param paginationRange
 * @param collectionLength
 * @returns {Array}
 */
export function generatePagesArray(
  currentPage: number,
  collectionLength: number,
  rowsPerPage: number,
  paginationRange: number
) {
  const pages = [];
  const totalPages = Math.ceil(collectionLength / rowsPerPage);
  const halfWay = Math.ceil(paginationRange / 2);
  let position;

  if (currentPage <= halfWay) {
    position = "start";
  } else if (totalPages - halfWay < currentPage) {
    position = "end";
  } else {
    position = "middle";
  }

  const ellipsesNeeded = paginationRange < totalPages;
  let i = 1;
  while (i <= totalPages && i <= paginationRange) {
    const pageNumber = calculatePageNumber(
      i,
      currentPage,
      paginationRange,
      totalPages
    );

    const openingEllipsesNeeded =
      i === 2 && (position === "middle" || position === "end");
    const closingEllipsesNeeded =
      i === paginationRange - 1 &&
      (position === "middle" || position === "start");
    if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
      pages.push("...");
    } else {
      pages.push(pageNumber);
    }
    i++;
  }
  return pages;
}

export function properCase(str?: string | null) {
  if (!str) return str ?? undefined;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function reduceArray<T>(arr: T[], index: number, count: number) {
  return arr.filter((v, i) => i >= index && i < index + count);
}
