import React, { useState } from "react";
import CognitoService from "../../services/cognitoService";
import { Dialog } from "primereact/dialog";
import { useAppDispatch } from "../../store";
import { alertActions } from "../../features/alert/alertSlice";
import { LocalStorage, LocalStorageKeys } from "../../utils/localStorage";
import { CognitoIdentityServiceProvider } from "aws-sdk";

export default function ChangePassword({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose(value: boolean): void;
}) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useAppDispatch();
  async function changeCognitoPassword() {
    if (newPassword === confirmPassword && newPassword != "") {
      try {
        await CognitoService.changePassword(oldPassword, newPassword);
      } catch (e) {
        if (e instanceof Error) {
          dispatch(
            alertActions.show({
              body: e.message || "Something is wrong",
              variant: "error",
            })
          );
          throw e;
        }
      }
      const userName = LocalStorage.get(LocalStorageKeys.USER_ATTRIBUTES) as {
        sub?: string;
      };
      const userNameToChangePassword = userName?.sub || "";
      const UserAttributes: CognitoIdentityServiceProvider.AttributeType[] = [
        {
          Name: "custom:password",
          Value: newPassword,
        },
      ];
      CognitoService.updateUserAttributes({
        Username: userNameToChangePassword,
        Attributes: UserAttributes,
      });
      dispatch(
        alertActions.show({ body: "successfully updated", variant: "success" })
      );
      setNewPassword("");
      setOldPassword("");
      setConfirmPassword("");
    } else {
      setNewPassword("");
      setOldPassword("");
      setConfirmPassword("");
      dispatch(
        alertActions.show({
          body: "New password and Confirm Password are not matching and should not be blank",
          variant: "error",
        })
      );
      return false;
    }
    onClose(false);
  }
  const footer = (
    <div className="actions">
      <div
        className="ui red cancel inverted button"
        style={{ boxShadow: "0 0 0 1px #ff695e inset", fontWeight: 500 }}
        onClick={() => onClose(false)}
      >
        <i className="remove icon"></i>
        Cancel
      </div>
      <div
        className="ui green ok inverted button"
        style={{ boxShadow: "0 0 0 1px #2ecc40 inset", fontWeight: 500 }}
        onClick={changeCognitoPassword}
      >
        <i className="checkmark icon"></i>
        Change Password
      </div>
    </div>
  );
  return (
    <Dialog
      visible={visible}
      onHide={() => {
        setNewPassword("");
        setOldPassword("");
        setConfirmPassword("");
        onClose(false);
      }}
      header={<div className="header">Change Password</div>}
      footer={footer}
      style={{ width: "41vw" }}
    >
      <div className="content">
        <div className="ui two column grid">
          <div className="row" style={{ paddingBottom: 0 }}>
            <div
              className="four wide column"
              style={{ fontSize: "small !important", lineHeight: "30px" }}
            >
              Old Password
            </div>
            <div className="twelve wide column">
              <input
                value={oldPassword}
                className="ng-pristine ng-valid ng-touched modal-input-box-custom"
                type="password"
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="row" style={{ paddingBottom: 0 }}>
            <div
              className="four wide column"
              style={{ fontSize: "small !important", lineHeight: "30px" }}
            >
              New Password
            </div>
            <div className="twelve wide column">
              <input
                value={newPassword}
                type="password"
                className="ng-pristine ng-valid ng-touched modal-input-box-custom"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="row" style={{ paddingBottom: 0 }}>
            <div
              className="four wide column"
              style={{ fontSize: "small !important", lineHeight: "30px" }}
            >
              Retype Password
            </div>
            <div className="twelve wide column">
              <input
                value={confirmPassword}
                className="ng-pristine ng-valid ng-touched modal-input-box-custom"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <p className="important-text">
            <strong>Note:</strong> Password must contain minimum 8 characters, 1
            uppercase letter, 1 lowercaseletter, 1 special character, and 1
            number
          </p>
        </div>
      </div>
    </Dialog>
    // <div className="ui tiny modal" id="passwordChangeModal">
    // </div>
  );
}
