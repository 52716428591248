import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Layout from "../components/ui/Layout";
import Home from "../screens/Home";
import Login from "../screens/Login";
import { NavigationPagePaths } from "./navigationPagePath";
import ProtectedRoute from "./ProtectedRoute";
import Responder from "../screens/Responder";
import InMarket from "../screens/InMarket";
import ReMarket from "../screens/ReMarket";
import User from "../screens/User";
import Admin from "../screens/Admin";
import Trigger from "../screens/Trigger";
import ChartReport from "../screens/ChartReport";
import ProtectAdmin from "./ProtectAdmin";

export const AppRoute = () => {
  return (
    <Routes>
      <Route path={NavigationPagePaths.LOGIN} element={<Login />} />
      <Route
        path={NavigationPagePaths.RESPONDER_PROFILES}
        element={
          <ProtectedRoute>
            <Responder />
          </ProtectedRoute>
        }
      />
      <Route
        path={NavigationPagePaths.IN_MARKET_MONITORING}
        element={
          <ProtectedRoute>
            <InMarket />
          </ProtectedRoute>
        }
      />
      <Route
        path={NavigationPagePaths.RE_MARKETING}
        element={
          <ProtectedRoute>
            <ReMarket />
          </ProtectedRoute>
        }
      />
      <Route
        path={NavigationPagePaths.CHART_REPORT}
        element={
          <ProtectedRoute>
            <ProtectAdmin>
              <ChartReport />
            </ProtectAdmin>
          </ProtectedRoute>
        }
      />
      <Route
        path={NavigationPagePaths.USER_BY_ID}
        element={
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        }
      />
      <Route
        path={NavigationPagePaths.TRIGGER_IN_MARKET_MONITORING_BY_ID}
        element={
          <ProtectedRoute>
            <Trigger />
          </ProtectedRoute>
        }
      />
      <Route
        path={NavigationPagePaths.TRIGGER_RE_MARKETING_BY_ID}
        element={
          <ProtectedRoute>
            <Trigger />
          </ProtectedRoute>
        }
      />
      <Route
        path={NavigationPagePaths.ADMIN}
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default function RouterComponent() {
  return (
    <Router>
      <Layout>
        <AppRoute />
      </Layout>
    </Router>
  );
}
