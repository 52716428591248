import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PersonalForm from "../components/form/PersonalForm";
import { IRecipient } from "../interfaces/recipient";
import OfferForm from "../components/form/OfferForm";
import SearchService from "../services/searchService";
import { TableNames } from "../constants/database";
import { useAppDispatch } from "../store";
import { spinnerActions } from "../features/spinner/spinnerSlice";
import { alertActions } from "../features/alert/alertSlice";
import { AppLightTheme } from "../utils/theme";
import { Button } from "primereact/button";
import { ExternalPaths } from "../router/navigationPagePath";

export default function User() {
  const { id } = useParams<{ id: string }>();
  const [recipient, setRecipient] = useState<Partial<IRecipient>>();
  const [zillowUrl, setZillowUrl] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(spinnerActions.load({ message: "Fetching Data..." }));
    SearchService.queryUserRecipientsByRecpCol(id || "0", TableNames.RECIPIENTS)
      .then((value) => {
        dispatch(spinnerActions.unload());
        setRecipient(value[0]);
      })
      .catch((err: Error) => {
        dispatch(spinnerActions.unload());
        dispatch(alertActions.show({ body: err.message, variant: "error" }));
      });
  }, []);
  function responded(
    markResponded: boolean,
    updatedData?: Partial<IRecipient>
  ) {
    // let respondedd: number = 0;
    // if (updatedData) {
    //   //On Note Api call(Note,status,mark responded change)
    //   respondedd = recipient?.status ? 1 : 0;
    // } else {
    //   // respondedd = recipient?.responded ? recipient?.responded :;
    // }

    // if (markResponded) {
    //   debugger;
    //   setRecipient((p) => ({ ...p, responded: 1 }));
    // }
    if (!recipient?.recipientscol) return;
    // const payload = {
    //   id: recipient?.recipientscol,
    //   notes: recipient?.notes ? recipient.notes : "",
    //   responded: respondedd,
    //   status: recipient?.status || "",
    // };
    let payload;
    if (updatedData) {
      payload = {
        id: recipient?.recipientscol,
        notes: recipient?.notes ? recipient.notes : "",
        responded: updatedData?.status ? 1 : 0, //updatedData?.status ? 1 : null,
        status: updatedData?.status || "",
      };
    } else if (markResponded) {
      payload = {
        id: recipient?.recipientscol,
        notes: recipient?.notes ? recipient.notes : "",
        responded: 1, //updatedData?.status ? 1 : null,
        status: recipient?.status || "",
      };
    } else {
      const respondedd = recipient?.responded ? recipient?.responded : 0;
      payload = {
        id: recipient?.recipientscol,
        notes: recipient?.notes ? recipient.notes : "",
        responded: respondedd, //recipient?.responded,
        status: recipient?.status || "",
      };
    }
    dispatch(spinnerActions.load({ message: "Updating..." }));
    SearchService.postNotes(payload, "recipients")
      .then((result) => {
        setRecipient(result[0] as IRecipient);
        dispatch(spinnerActions.unload());
        dispatch(
          alertActions.show({
            body: "Data updated Successfully",
            variant: "success",
          })
        );
      })
      .catch((err: Error) => {
        dispatch(spinnerActions.unload());
        dispatch(alertActions.show({ body: err.message, variant: "error" }));
      });
  }
  function saveUserAttributes(resp?: number) {
    const payload = {
      EMAIL: recipient?.EMAIL,
      PHONE_NUMBER: recipient?.PHONE_NUMBER,
      LO_ASSIGNED: recipient?.LO_ASSIGNED,
      status: recipient?.status,
      responded: resp ?? 0,
      ADDRESSLINE1: recipient?.ADDRESSLINE1,
      ADDRESSLINE2: recipient?.ADDRESSLINE2,
      recipientscol: recipient?.recipientscol,
      table: "recipients",
    };
    dispatch(spinnerActions.load({ message: "Updating..." }));
    SearchService.postUser(payload)
      .then(() => {
        dispatch(spinnerActions.unload());
        responded(false);
      })
      .catch((err: Error) => {
        dispatch(spinnerActions.unload());
        dispatch(alertActions.show({ body: err.message, variant: "error" }));
      });
  }
  function statusUpdateAndSaveUserAttributes() {
    // setRecipient((p) => ({ ...p, responded: 1 }));
    // saveUserAttributes(1);
    responded(true);
  }

  const fetchZillowUrl = async () => {
    if (recipient) {
      const { ADDRESSLINE1, ADDRESSLINE2, CITY, STATE } = recipient;
      const cleanedAddressLine2 =
        ADDRESSLINE2 && ADDRESSLINE2.trim() !== "null"
          ? ADDRESSLINE2.trim()
          : "";
      try {
        const response = await fetch(
          `${ExternalPaths.ZILLOW_URL_WITH_TOKEN}${ADDRESSLINE1}${cleanedAddressLine2}&city=${CITY}&state=${STATE}`
        );
        if (!response.ok) {
          throw new Error(
            `Failed to fetch data from Zillow. Status: ${response.status}`
          );
        }

        const zillowUrlData = await response.json();
        // const zillowUrl = zillowUrlData?.bundle[0]?.zillowUrl;
        if (
          zillowUrlData?.bundle?.length &&
          zillowUrlData?.bundle?.length >= 0
        ) {
          const zillowUrl = zillowUrlData?.bundle[0]?.zillowUrl;
          window.open(zillowUrl, "_blank");
        } else {
          dispatch(
            alertActions.show({
              body: "The Zillow Profile is not available for this record",
              variant: "error",
            })
          );
        }
        // setZillowUrl(zillowUrl);
      } catch (error) {
        console.error("Error fetching Zillow data:", error);
      }
    }
  };
  return (
    <div>
      <div
        className="w-full flex align-items-center justify-content-between flex-wrap card-container mb-2 px-4"
        style={{ color: AppLightTheme.backgroundColor.NAV_BAR }}
      >
        <h2 className="my-0 ml-2">
          <i className="fa-solid fa-user mx-2"></i>
          {recipient?.FULLNAME || "Full name"}
        </h2>
        <div>
          <Button
            label="Zillow URL"
            outlined
            style={{
              marginRight: 10,
              color: AppLightTheme.color.PRIMARY,
            }}
            onClick={fetchZillowUrl}
          />

          <Button
            label={recipient?.responded ? "Responded" : "Mark Responded"}
            disabled={!!recipient?.responded}
            style={{
              backgroundColor: AppLightTheme.color.PRIMARY,
              borderColor: AppLightTheme.borderColor.PRIMARY,
            }}
            onClick={statusUpdateAndSaveUserAttributes}
          />
        </div>
      </div>
      <PersonalForm
        type={"recipient"}
        data={recipient}
        setData={setRecipient}
        statusUpdate={(updatedData) => responded(false, updatedData)}
      />
      <OfferForm
        type={"recipient"}
        data={recipient}
        setData={setRecipient}
        onCancel={() => {
          navigate(-1);
        }}
        onUpdate={() => saveUserAttributes()}
      />
    </div>
  );
}
