import React, { forwardRef } from "react";
import { AppLightTheme } from "../../utils/theme";
import { Link, useLocation } from "react-router-dom";
import {
  ExternalPaths,
  NavigationPagePaths,
} from "../../router/navigationPagePath";
import { useAppSelector } from "../../store";
import residentialLoanApplication from "../../assets/documents/residentialLoanApplication.pdf";
import { LocalStorage, LocalStorageKeys } from "../../utils/localStorage";

const SideBar = forwardRef<HTMLDivElement, { closeNav: () => void }>(
  ({ closeNav }, sidebarRef) => {
    const { auth, userAttributes } = useAppSelector((state) => state.user);
    const { pathname } = useLocation();

    const showReMarketing =
      userAttributes && userAttributes["custom:ReMarketing"] === "1";
    const showResponderProfile =
      userAttributes && userAttributes["custom:ResponderProfile"] === "1";
    const showInMarketMonitoring =
      userAttributes && userAttributes["custom:InMarketMonitoring"] === "1";
    // const showCamber =
    //   userAttributes && userAttributes["custom:Camber"] === "1";
    const localdata: any = LocalStorage.get(LocalStorageKeys.USER_ATTRIBUTES);

    return (
      <div id="sidenav" className="sidenav" ref={sidebarRef}>
        <div
          className="closebtn"
          style={{
            color: "white",
            float: "right",
            right: 0,
            margin: "0.2em ",
            cursor: "pointer",
          }}
          onClick={closeNav}
        >
          &times;
        </div>
        <div>
          <div
            style={{
              backgroundColor: AppLightTheme.backgroundColor.USER_ICON,
              color: AppLightTheme.color.USER_ICON,
              borderRadius: "50%",
              width: "4em",
              height: "4em",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <i className="fa-solid fa-user" style={{ fontSize: "2.2em" }}></i>
          </div>
          <label
            className="my-2"
            style={{
              display: "block",
              textAlign: "center",
              width: "100%",
              color: AppLightTheme.backgroundColor.USER_ICON,
            }}
          >
            {userAttributes?.["email"] || "User"}
          </label>
        </div>
        {auth?.loggedIn && (
          <>
            {showResponderProfile && (
              <div
                style={{
                  backgroundColor:
                    pathname === NavigationPagePaths.RESPONDER_PROFILES ||
                    pathname.split("/").slice(0, -1).join("/") + "/" == //Finding the path name by removing the user id to highlight the corrosponding menu
                      NavigationPagePaths.USER_BY_ID.split(":")[0]
                      ? "black"
                      : "transparent",
                  opacity:
                    pathname === NavigationPagePaths.RESPONDER_PROFILES ||
                    pathname.split("/").slice(0, -1).join("/") + "/" ==
                      NavigationPagePaths.USER_BY_ID.split(":")[0]
                      ? ".3"
                      : "1",
                }}
              >
                <Link
                  to={NavigationPagePaths.RESPONDER_PROFILES}
                  className={
                    location.pathname.includes(
                      NavigationPagePaths.RESPONDER_PROFILES
                    )
                      ? "active menu item"
                      : "inactive item TopMenu"
                  }
                >
                  Search Responder Profiles
                </Link>
              </div>
            )}
            {showInMarketMonitoring && (
              <div
                style={{
                  backgroundColor:
                    pathname === NavigationPagePaths.IN_MARKET_MONITORING ||
                    pathname.split("/").slice(0, -1).join("/") + "/" ==
                      NavigationPagePaths.TRIGGER_IN_MARKET_MONITORING_BY_ID.split(
                        ":"
                      )[0]
                      ? "black"
                      : "transparent",
                  opacity:
                    pathname === NavigationPagePaths.IN_MARKET_MONITORING ||
                    pathname.split("/").slice(0, -1).join("/") + "/" ==
                      NavigationPagePaths.TRIGGER_IN_MARKET_MONITORING_BY_ID.split(
                        ":"
                      )[0]
                      ? ".3"
                      : "1",
                }}
              >
                <Link
                  to={NavigationPagePaths.IN_MARKET_MONITORING}
                  className={
                    location.pathname.includes(
                      NavigationPagePaths.IN_MARKET_MONITORING
                    )
                      ? "active menu item"
                      : "inactive item TopMenu"
                  }
                >
                  In-Market Monitoring
                </Link>
              </div>
            )}
            {showReMarketing && (
              <div
                style={{
                  backgroundColor:
                    pathname === NavigationPagePaths.RE_MARKETING ||
                    pathname.split("/").slice(0, -1).join("/") + "/" ==
                      NavigationPagePaths.TRIGGER_RE_MARKETING_BY_ID.split(
                        ":"
                      )[0]
                      ? "black"
                      : "transparent",
                  opacity:
                    pathname === NavigationPagePaths.RE_MARKETING ||
                    pathname.split("/").slice(0, -1).join("/") + "/" ==
                      NavigationPagePaths.TRIGGER_RE_MARKETING_BY_ID.split(
                        ":"
                      )[0]
                      ? ".3"
                      : "1",
                }}
              >
                <Link
                  to={NavigationPagePaths.RE_MARKETING}
                  className={
                    location.pathname.includes(NavigationPagePaths.RE_MARKETING)
                      ? "active menu item"
                      : "inactive item TopMenu"
                  }
                >
                  ReMarketing
                </Link>
              </div>
            )}

            <a
              href={ExternalPaths.CALL_CAMBERMARKETING}
              target="_blank"
              className={
                location.pathname.includes(ExternalPaths.CALL_CAMBERMARKETING)
                  ? "active menu item"
                  : "inactive item TopMenu"
              }
              rel="noreferrer"
            >
              Campaign Call Tracking
            </a>
            <a
              href={ExternalPaths.MORTGAGE_CALCULATOR}
              target="_blank"
              className={
                location.pathname.includes(ExternalPaths.CALL_CAMBERMARKETING)
                  ? "active menu item"
                  : "inactive item TopMenu"
              }
              rel="noreferrer"
            >
              Mortgage Calculator
            </a>
            <a
              href={residentialLoanApplication}
              target="_blank"
              className={
                location.pathname.includes(ExternalPaths.CALL_CAMBERMARKETING)
                  ? "active menu item"
                  : "inactive item TopMenu"
              }
              rel="noopener noreferrer"
            >
              Loan Application (Form 1003)
            </a>
            {localdata?.["custom:Camber"] === "1" ? (
              <Link
                to={NavigationPagePaths.CHART_REPORT}
                className={
                  location.pathname.includes(NavigationPagePaths.CHART_REPORT)
                    ? "active menu item"
                    : "inactive item TopMenu"
                }
              >
                Reports
              </Link>
            ) : null}
          </>
        )}
      </div>
    );
  }
);

SideBar.displayName = "SideBar";
export default SideBar;
