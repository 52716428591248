import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IQueryRecipient } from "../../interfaces/recipient";
import { IQueryResponse } from "../../interfaces/response";
import { spinnerActions } from "../spinner/spinnerSlice";
import { alertActions } from "../alert/alertSlice";
import { alertVariant } from "../../constants/alert";
import SearchService from "../../services/searchService";
import { TableNames } from "../../constants/database";

interface IRecipientPayload {
  term: string;
  // name: string;
  // address: string;
  // offerCode: string;
  loAssigned: string;
  startDate: Date | null;
  endDate: Date | null;
  status: string;
  pageLimit: number;
  currentPage: number;
}
const initialState: IQueryResponse<IQueryRecipient> = [[], [{ count: 0 }]];
const fetchData = createAsyncThunk(
  "recipient/fetch",
  async (
    {
      term = "",
      // name = "",
      // address = "",
      // offerCode = "",
      loAssigned = "",
      status = "",
      startDate,
      endDate,
      pageLimit,
      currentPage,
    }: IRecipientPayload,
    { dispatch }
  ) => {
    try {
      dispatch(spinnerActions.load({ message: "Requesting Data..." }));
      const results = (await SearchService.queryUserRecipientsByNameLOCriteria(
        "responders",
        term,
        // name,
        // address,
        // offerCode,
        loAssigned,
        startDate,
        endDate,
        TableNames.RECIPIENTS,
        status,
        pageLimit,
        currentPage
      )) as IQueryResponse<IQueryRecipient>;
      dispatch(spinnerActions.unload());
      return results as IQueryResponse<IQueryRecipient>;
    } catch (e) {
      if (e instanceof Error) {
        dispatch(spinnerActions.unload());
        dispatch(
          alertActions.show({
            variant: alertVariant.error,
            body: e.message || "Something is wrong",
          })
        );
        throw e;
      }
    }
  }
);
export const recipientSlice = createSlice({
  name: "recipient",
  initialState,
  reducers: {
    load(
      state: IQueryResponse<IQueryRecipient>,
      action: PayloadAction<IQueryResponse<IQueryRecipient>>
    ): IQueryResponse<IQueryRecipient> {
      return action.payload;
    },
    clear(): IQueryResponse<IQueryRecipient> {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      if (!action.payload) return state;
      state = structuredClone
        ? structuredClone(action.payload)
        : { ...action.payload };
      return state;
    });
  },
});

export const recipientActions = { ...recipientSlice.actions, fetchData };

export default recipientSlice.reducer;
