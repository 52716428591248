import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISpinner } from "../../interfaces/spinner";

const initialState: ISpinner = {
  count: 0,
  message: "Loading...",
};
export const spinnerSlice = createSlice({
  name: "spinner",
  initialState,
  reducers: {
    load(
      state: ISpinner,
      action: PayloadAction<{ message?: string }>
    ): ISpinner {
      return {
        count: state.count + 1,
        message: action.payload.message || "Loading...",
      };
    },
    unload(state: ISpinner): ISpinner {
      if (state.count === 0) return state;
      return {
        count: state.count - 1,
        message: "Loading...",
      };
    },
  },
});

export const spinnerActions = { ...spinnerSlice.actions };

export default spinnerSlice.reducer;
