import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import CognitoService from "../../services/cognitoService";
import { useAppDispatch } from "../../store";
import { spinnerActions } from "../../features/spinner/spinnerSlice";
import { alertActions } from "../../features/alert/alertSlice";

export default function NewUserForm({
  visible,
  onClose,
  onUserCreated,
}: {
  visible: boolean;
  onClose(value: boolean): void;
  onUserCreated(): void;
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [admin, setAdmin] = useState(false);
  const [clientName, setClientName] = useState("");
  const [branches, setBranches] = useState("");
  const [responderprofile, setResponderprofile] = useState(false);
  const [inmarketmonitoring, setInmarketmonitoring] = useState(false);
  const [remarketing, setRemarketing] = useState(false);
  const [formVisible, setFormVisible] = useState(visible);

  const dispatch = useAppDispatch();
  useEffect(() => {
    setFormVisible(visible); // Update the form visibility when the 'visible' prop changes
  }, [visible]);

  useEffect(() => {
    if (admin) {
      setResponderprofile(true);
      setInmarketmonitoring(true);
      setRemarketing(true);
    }
  }, [admin]);

  const handleEmailChange = (e: any) => {
    const lowercaseEmail = e.target.value.toLowerCase();
    setEmail(lowercaseEmail);
  };
  function createUser() {
    dispatch(spinnerActions.load({ message: "Creating User..." }));
    CognitoService.registerUser({
      email,
      admin,
      first: firstName,
      last: lastName,
      clientName,
      branches,
      responderprofile,
      inmarketmonitoring,
      remarketing,
    })
      .then(() => {
        onUserCreated();
        setFormVisible(false);
        onClose(false);
        dispatch(spinnerActions.unload());
        dispatch(
          alertActions.show({
            body: "User added Successfully",
            variant: "success",
          })
        );
      })
      .catch((e: Error) => {
        dispatch(spinnerActions.unload());
        dispatch(alertActions.show({ body: e.message, variant: "error" }));
        setFormVisible(false);
        onClose(false);
      });
  }

  const footer = (
    <div className="actions">
      <div className="ui black deny button" onClick={() => onClose(false)}>
        Cancel
      </div>
      <div
        id="saveWatchlistModal"
        className="ui positive button"
        onClick={createUser}
      >
        Save
      </div>
    </div>
  );
  return (
    <Dialog
      visible={formVisible}
      onHide={() => onClose(false)}
      header={<div className="header">Create New User</div>}
      footer={footer}
      style={{ width: "41vw" }}
    >
      <form name="newUser" className="ui form">
        <div className="two fields">
          <div className="field">
            <label>First Name:</label>
            <input
              name="first"
              type="text"
              placeholder="Enter First Name..."
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="field">
            <label>Last Name:</label>
            <input
              name="last"
              type="text"
              placeholder="Enter Last Name..."
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="two fields">
          <div className="field">
            <label>Email:</label>
            <input
              name="email"
              style={{ width: "100%" }}
              type="text"
              placeholder="Enter Email..."
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div className="field">
            <div className="ui checkbox">
              <input
                name="admin"
                type="checkbox"
                defaultChecked={admin}
                onChange={() => setAdmin((p) => !p)}
              />
              <label>Camber Admin?</label>
            </div>
          </div>
        </div>
        <div className="two fields">
          <div className="field">
            <label>Client Name:</label>
            <input
              name="clientName"
              type="text"
              placeholder="Enter Client Name..."
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            />
          </div>
          <div className="field">
            <label>Branches:</label>
            <input
              name="branches"
              type="text"
              placeholder="Enter Branches..."
              value={branches}
              onChange={(e) => setBranches(e.target.value)}
            />
          </div>
        </div>
        <div className="three fields">
          <div className="field">
            <div className="ui checkbox">
              <input
                name="responderProfile"
                type="checkbox"
                disabled={admin}
                checked={responderprofile}
                onChange={() => setResponderprofile((p) => !p)}
              />
              <label>Responder Profile?</label>
            </div>
          </div>
          <div className="field">
            <div className="ui checkbox">
              <input
                name="inMarket"
                type="checkbox"
                disabled={admin}
                checked={inmarketmonitoring}
                onChange={() => setInmarketmonitoring((p) => !p)}
              />
              <label>In-Market Monitoring?</label>
            </div>
          </div>
          <div className="field">
            <div className="ui checkbox">
              <input
                name="admin"
                type="checkbox"
                disabled={admin}
                checked={remarketing}
                onChange={() => setRemarketing((p) => !p)}
              />
              <label>ReMarketing?</label>
            </div>
          </div>
        </div>
      </form>
    </Dialog>
  );
}
