import React, { useState, useEffect } from "react";
import { AppLightTheme } from "../utils/theme";
import { Button } from "primereact/button";
import {
  ExternalPaths,
  NavigationPagePaths,
} from "../router/navigationPagePath";
import PersonalForm from "../components/form/PersonalForm";
import OfferForm from "../components/form/OfferForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ITrigger } from "../interfaces/trigger";
import { useAppDispatch } from "../store";
import { spinnerActions } from "../features/spinner/spinnerSlice";
import SearchService from "../services/searchService";
import { alertActions } from "../features/alert/alertSlice";

export default function Trigger() {
  const { id } = useParams();
  //   const id = param.id;
  const [table, setTable] = useState("");
  const [trigger, setTrigger] = useState<Partial<ITrigger>>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    dispatch(spinnerActions.load({ message: "Fetching Data..." }));
    const table = location.pathname.includes(
      NavigationPagePaths.IN_MARKET_MONITORING
    )
      ? "in_market_triggers"
      : "triggers";
    setTable(table);
    console.log(id);
    SearchService.getRecord(id || "0", table)
      .then((value) => {
        dispatch(spinnerActions.unload());
        setTrigger(value[0]);
      })
      .catch((err: Error) => {
        dispatch(spinnerActions.unload());
        dispatch(alertActions.show({ body: err.message, variant: "error" }));
      });
  }, []);
  function responded(markResponded: boolean, updatedData?: Partial<ITrigger>) {
    // const respondedd = trigger?.responded ? 0 : 1;
    // if (markResponded) {
    //   setTrigger((p) => ({ ...p, responded: respondedd }));
    // }
    if (!trigger?.recipientscol) return;
    let payload;
    if (updatedData) {
      payload = {
        id: trigger?.recipientscol,
        notes: trigger?.notes ? trigger.notes : "",
        responded: updatedData?.status ? 1 : 0,
        status: updatedData?.status || "",
      };
    } else if (markResponded) {
      payload = {
        id: trigger?.recipientscol,
        notes: trigger?.notes ? trigger.notes : "",
        responded: 1,
        status: trigger?.status || "",
      };
    } else {
      const respondedd = trigger?.responded ? trigger?.responded : 0;
      payload = {
        id: trigger?.recipientscol,
        notes: trigger?.notes ? trigger.notes : "",
        responded: respondedd,
        status: trigger?.status || "",
      };
    }
    dispatch(spinnerActions.load({ message: "Updating..." }));
    SearchService.postNotes(payload, table)
      .then((result) => {
        setTrigger(result[0] as ITrigger);
        dispatch(spinnerActions.unload());
        dispatch(
          alertActions.show({
            body: "Data updated Successfully",
            variant: "success",
          })
        );
      })
      .catch((err: Error) => {
        dispatch(spinnerActions.unload());
        dispatch(alertActions.show({ body: err.message, variant: "error" }));
      });
  }
  function saveUserAttributes(resp?: number) {
    const payload = {
      EMAIL: trigger?.EMAIL || "",
      PHONE_NUMBER: trigger?.PHONE_NUM || "",
      LO_ASSIGNED: trigger?.LO_ASSIGNED || "",
      status: trigger?.status || null,
      responded: trigger?.responded ?? (0 || null),
      ADDRESSLINE1: trigger?.ADDRESS || null,
      ADDRESSLINE2: trigger?.ADDRESS2 || null,
      ...(trigger?.recipientscol && { recipientscol: trigger?.recipientscol }),
      table,
    };
    dispatch(spinnerActions.load({ message: "Updating..." }));
    SearchService.postUser(payload)
      .then(() => {
        dispatch(spinnerActions.unload());
        responded(false);
      })
      .catch((err: Error) => {
        dispatch(spinnerActions.unload());
        dispatch(alertActions.show({ body: err.message, variant: "error" }));
      });
  }
  function statusUpdateAndSaveUserAttributes() {
    // setTrigger((p) => ({ ...p, responded: 1 }));
    // saveUserAttributes(1);
    responded(true);
  }
  return (
    <div>
      <div
        className="w-full flex align-items-center justify-content-between flex-wrap card-container mb-2 px-4"
        style={{ color: AppLightTheme.backgroundColor.NAV_BAR }}
      >
        <h2 className="my-0 ml-2">
          <i className="fa-solid fa-user mx-2"></i>
          {trigger?.FULLNAME || "Full name"}
        </h2>
        <div>
          {/* <a href={ExternalPaths.ZILLOW_URL} target={"_blank"} rel="noreferrer">
            <Button
              label="Zillow URL"
              outlined
              style={{
                color: AppLightTheme.color.PRIMARY,
              }}
            />
          </a>{" "} */}
          <Button
            label={trigger?.responded ? "Responded" : "Mark Responded"}
            disabled={!!trigger?.responded}
            style={{
              backgroundColor: AppLightTheme.color.PRIMARY,
              borderColor: AppLightTheme.borderColor.PRIMARY,
            }}
            onClick={statusUpdateAndSaveUserAttributes}
          />
        </div>
      </div>
      <PersonalForm
        type={"trigger"}
        data={trigger}
        setData={setTrigger}
        statusUpdate={(updatedData) => responded(false, updatedData)}
      />
      <OfferForm
        type={"trigger"}
        data={trigger}
        setData={setTrigger}
        onCancel={() => {
          navigate(-1);
        }}
        onUpdate={saveUserAttributes}
      />
    </div>
  );
}
