import moment from "moment";
import { ROOT_URL } from "../constants/aws_config";
import { IAuth } from "../interfaces/auth";
import { IQueryRecipient, IRecipient } from "../interfaces/recipient";
import { IQueryResponse, IResultResponse } from "../interfaces/response";
import { IQueryTrigger, ITrigger } from "../interfaces/trigger";
import Fetcher from "../lib/fetcher";
import { LocalStorage, LocalStorageKeys } from "../utils/localStorage";
import CognitoService from "./cognitoService";

function searchService() {
  function getConfig() {
    const auth = LocalStorage.get<IAuth>(LocalStorageKeys.AUTH);
    if (!auth || !auth.idToken) return undefined;
    return {
      "Content-Type": "application/json",
      Authorization: auth.idToken,
    };
  }
  function getAttribute(o: Record<string, string>, property: string) {
    if (Object.hasOwn(o, property)) {
      return o[property];
    }
    return "";
  }
  function postUserAttributes(
    email: string,
    phone: string,
    lo_assigned: string,
    recipientscol: string,
    tableName: string
  ) {
    const body = {
      email: email,
      phone_number: phone,
      lo_assigned: lo_assigned,
      recipientscol: recipientscol,
      table: tableName,
    };
    return Fetcher.post<
      typeof body,
      IQueryResponse<IQueryRecipient | IQueryTrigger>
    >(`${ROOT_URL}/update-data-attributes`, body, getConfig());
  }

  /**
   * Get `Recipient` by `recipientscol` id
   * @param id `recipientscol` id
   * @param table Table name for now `recipients_v` | `recipients`
   * @returns {Promise} Recipient result
   */
  async function queryUserRecipientsByRecpCol(id: string, table: string) {
    await CognitoService.getSession();
    await CognitoService.getUserAttributes();
    const url = `${ROOT_URL}/getrecord?id=${id}&table=${table}`;
    return Fetcher.get<IResultResponse<IRecipient>>(url, getConfig());
  }

  type IQueryLOCriteriaParams = [
    term: string,
    // nameSearch: string,
    // address: string,
    // offerCode: string,
    loSearch: string,
    startDate: Date | string | null,
    endDate: Date | string | null,
    table: string,
    status: string,
    limit: number,
    pageNumber: number
  ];
  /**
   *
   * @param {string} type The type of the call
   * @param {string} nameSearch The search string entered for Name, Address or Offer code
   * @param {string} address The search string entered for Name, Address or Offer code
   * @param {string} offerCode The search string entered for Name, Address or Offer code
   * @param {string} loSearch The search string entered for LO Assigned
   * @param {string} startDate The mail start date
   * @param {string} endDate The mail end date
   * @param {string} table The target table
   * @param {string} status The selected status
   * @param {number} limit The limit of record
   * @param {number} pageNumber The pageNumber of record
   * @returns
   */
  async function queryUserRecipientsByNameLOCriteria(
    type: "responders",
    ...params: IQueryLOCriteriaParams
  ): Promise<IQueryResponse<IQueryRecipient>>;
  async function queryUserRecipientsByNameLOCriteria(
    type: "getremarketingtriggers",
    ...params: IQueryLOCriteriaParams
  ): Promise<IQueryResponse<IQueryTrigger>>;
  async function queryUserRecipientsByNameLOCriteria(
    type: "responders" | "getremarketingtriggers",
    ...[
      term,
      // nameSearch,
      // address,
      // offerCode,
      loSearch,
      startDate,
      endDate,
      table,
      status,
      limit,
      pageNumber,
    ]: IQueryLOCriteriaParams
  ) {
    await CognitoService.getSession();
    const attrs = await CognitoService.getUserAttributes();
    if (!startDate) {
      startDate = endDate = "";
      // endDate = moment().format('YYYY-MM-DD');
    }
    if (!term) term = "";
    // if (!nameSearch) nameSearch = "";
    // if (!address) address = "";
    // if (!offerCode) offerCode = "";
    if (!loSearch) loSearch = "";
    const attributes = CognitoService.processUserAttributes(attrs);
    const client_name = getAttribute(attributes, "custom:client_name");
    const branches = getAttribute(attributes, "custom:branches");
    let url = `${ROOT_URL}/${
      type === "responders" ? "getresponders" : "getremarketingtriggers"
    }?loassigned=${encodeURIComponent(loSearch)}&term=${encodeURIComponent(
      term
    )}&startDate=${startDate}&endDate=${endDate}&table=${table}&status=${status}&limit=${limit}&pageNumber=${
      (pageNumber - 1) * limit
    }`;
    if (client_name) {
      url = url + "&client_name=" + client_name;
    }
    if (branches) {
      url = url + "&branch=" + branches;
    }
    if (type == "responders") {
      return Fetcher.get<IQueryResponse<IQueryRecipient>>(url, getConfig());
    }
    return Fetcher.get<IQueryResponse<IQueryTrigger>>(url, getConfig());
  }

  function postUser(userObjct: Partial<IRecipient> & { table: string }) {
    const body = {
      email: userObjct.EMAIL,
      phone_number: userObjct.PHONE_NUMBER,
      lo_assigned: userObjct.LO_ASSIGNED,
      addressLine1: userObjct.ADDRESSLINE1,
      addressLine2: userObjct.ADDRESSLINE2,
      city: userObjct.CITY,
      state: userObjct.STATE,
      zip: userObjct.ZIP,
      responded: userObjct.responded,
      status: userObjct.status,
      recipientscol: userObjct.recipientscol,
      table: userObjct.table,
    };
    return Fetcher.post<
      typeof body,
      IQueryResponse<IQueryRecipient | IQueryTrigger>
    >(ROOT_URL + "/update-data-attributes", body, getConfig());
  }

  async function getRecord(id: string, table: string) {
    await CognitoService.getSession();
    const url = ROOT_URL + "/getrecord" + "?id=" + id + "&table=" + table;
    return Fetcher.get<IResultResponse<ITrigger>>(url, getConfig());
  }

  function postNotes(
    notes: {
      id: number;
      notes: string;
      responded: number;
      status: string;
    },
    table: string
  ) {
    // var url = 'https://wvomql1tjj.execute-api.us-west-2.amazonaws.com/Prod';
    const data = { notes: notes, table: table };
    // console.log('data', data);
    return Fetcher.post<typeof data, IResultResponse<IRecipient | ITrigger>>(
      ROOT_URL,
      data,
      getConfig()
    );
  }

  function updateEmail(uid: string, email: string) {
    const body = { uid: uid, email: email };
    return Fetcher.post<typeof body, unknown>(
      ROOT_URL + "updateemail",
      body,
      getConfig()
    );
  }

  function resetPassword(uid: string) {
    const body = { username: uid };
    return Fetcher.post<typeof body, unknown>(
      ROOT_URL + "resetpassword",
      body,
      getConfig()
    );
  }

  async function chartReport(tableName: string, category: string) {
    const url =
      ROOT_URL +
      "/report" +
      "?recipients=" +
      tableName +
      "&category=" +
      category;
    return Fetcher.get<IResultResponse<ITrigger>>(url, getConfig());
  }

  function updateUserAttributes(uid: string, email: string) {
    const body = { uid: uid, Name: "email", Value: email };
    return Fetcher.post<typeof body, unknown>(
      ROOT_URL + "user-attribute",
      body,
      getConfig()
    );
  }

  async function downloadCsv(
    type: "responders" | "getremarketingtriggers",
    table: string,
    term: string,
    // name: string,
    // address: string,
    // offerCode: string,
    loAssigned: string,
    status: string,
    startDate: Date | null,
    endDate: Date | null,
    limit: number,
    pageNumber: number
  ) {
    if (
      (term && term.length >= 3) ||
      // (name && name.length >= 3) ||
      // (address && address.length >= 3) ||
      // (offerCode && offerCode.length >= 3) ||
      (loAssigned && loAssigned.length >= 3) ||
      (startDate && endDate) ||
      (status && status.length >= 1)
    ) {
      term = term?.toUpperCase();
      // name = name?.toUpperCase();
      // address = address?.toUpperCase();
      // offerCode = offerCode?.toUpperCase();
      loAssigned = loAssigned?.toUpperCase();
      let csvString = "";
      if (type === "responders") {
        const response = await queryUserRecipientsByNameLOCriteria(
          type,
          term,
          // name,
          // address,
          // offerCode,
          loAssigned,
          startDate,
          endDate,
          table,
          status,
          limit,
          pageNumber
        );
        const csvData = response[0];
        csvString =
          "FIRST_NANE,LAST_NAME,ADDRESS_LINE1,ADDRESS_LINE2,CITY,STATE,ZIP,MAIL_DATE,OFFER_CODE,LO_ASSIGNED,NOTES,PHONE_NUMBER,EMAIL," +
          "\n";
        for (let rowIndex = 0; rowIndex < csvData.length; rowIndex++) {
          const rowData = csvData[rowIndex];
          csvString =
            csvString +
            `"${rowData["FNAME"] ?? ""}",` +
            `"${rowData["LNAME"] ?? ""}",` +
            `"${rowData["ADDRESSLINE1"] ?? ""}",` +
            `"${rowData["ADDRESSLINE2"] ?? ""}",` +
            `"${rowData["CITY"] ?? ""}",` +
            `"${rowData["STATE"] ?? ""}",` +
            `"${rowData["ZIP"] ?? ""}",` +
            moment(rowData["MAIL_DATE"]).format("MM/DD/YYYY") +
            "," +
            `"${rowData["OFFER_CODE"] ?? ""}",` +
            `"${rowData["LO_ASSIGNED"] ?? ""}",` +
            `"${rowData["notes"] ?? ""}",` +
            `"${rowData["PHONE_NUMBER"] ?? ""}",` +
            `"${rowData["EMAIL"] ?? ""}",` +
            "\n";
        }
      } else {
        const response = await queryUserRecipientsByNameLOCriteria(
          type,
          term,
          // name,
          // address,
          // offerCode,
          loAssigned,
          startDate,
          endDate,
          table,
          status,
          limit,
          pageNumber
        );
        const csvData = response[0];
        csvString =
          "FIRST_NANE,LAST_NAME,STREET,CITY,STATE,ZIP,OFFER_CODE,TRIGGER_DATE,CLIENT_NAME,UPLOAD_DATE,LO_ASSIGNED,NOTES,PHONE_NUMBER,EMAIL," +
          "\n";
        for (let rowIndex = 0; rowIndex < csvData.length; rowIndex++) {
          const rowData = csvData[rowIndex];
          csvString =
            csvString +
            `"${rowData["FNAME"] ?? ""}",` +
            `"${rowData["LNAME"] ?? ""}",` +
            `"${rowData["ADDRESSLINE1"] ?? ""}",` +
            `"${rowData["CITY"] ?? ""}",` +
            `"${rowData["STATE"] ?? ""}",` +
            `"${rowData["ZIP"] ?? ""}",` +
            `"${rowData["OFFER_CODE"] ?? ""}",` +
            moment(rowData["TRIGGER_DATE"]).format("MM/DD/YYYY") +
            "," +
            `"${rowData["CLIENT_NAME"] ?? ""}",` +
            moment(rowData["UPLOAD_DATE"]).format("MM/DD/YYYY") +
            "," +
            `"${rowData["LO_ASSIGNED"] ?? ""}",` +
            `"${rowData["notes"] ?? ""}",` +
            `"${rowData["PHONE_NUM"] ?? ""}",` +
            `"${rowData["EMAIL"] ?? ""}",` +
            "\n";
        }
      }
      const a = document.createElement("a");
      a.style.display = "none";
      a.href =
        "data:application/octet-stream;base64," +
        btoa(unescape(encodeURIComponent(csvString)));

      a.download = new Date() + ".csv";
      a.click();
      a.remove();
    }
  }

  return {
    postUserAttributes,
    queryUserRecipientsByRecpCol,
    queryUserRecipientsByNameLOCriteria,
    postUser,
    getRecord,
    postNotes,
    updateEmail,
    resetPassword,
    updateUserAttributes,
    downloadCsv,
    chartReport,
  };
}

const SearchService = searchService();

export default SearchService;
