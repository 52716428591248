import React, { useMemo } from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./style.css";
import { AppLightTheme } from "../../utils/theme";
import { Button } from "primereact/button";
import { IQueryTypeParams } from "../../interfaces/response";
import { useAppSelector } from "../../store";
import Paginator from "../ui/Paginator";
import moment from "moment";
import { Link } from "react-router-dom";
import { NavigationPagePaths } from "../../router/navigationPagePath";

type ITabulationParams = IQueryTypeParams & {
  currentPage: number;
  pageLimit: number;
  marketType?: "in-market" | "re-market";
  onPageChanged?: (newPageNumber: number) => void;
  onExportCsv?: () => void;
};
export default function Tabulation({
  type,
  data,
  currentPage,
  pageLimit,
  marketType,
  onPageChanged,
  onExportCsv,
}: ITabulationParams) {
  function linking(value: React.ReactNode, id: string) {
    if (type === "recipient") {
      return (
        <Link to={NavigationPagePaths.USER_BY_ID.replace(":id", id)}>
          {value}
        </Link>
      );
    } else if (marketType === "in-market") {
      return (
        <Link
          to={NavigationPagePaths.TRIGGER_IN_MARKET_MONITORING_BY_ID.replace(
            ":id",
            id
          )}
        >
          {value}
        </Link>
      );
    }
    return (
      <Link
        to={NavigationPagePaths.TRIGGER_RE_MARKETING_BY_ID.replace(":id", id)}
      >
        {value}
      </Link>
    );
  }
  const tableData = useMemo(() => {
    if (type === "recipient") {
      return data[0]?.map((value) => ({
        ...value,
        FULLNAME: linking(value.FULLNAME, value.recipientscol.toString()),
        MAIL_DATE: value.MAIL_DATE
          ? moment.utc(value.MAIL_DATE).format("MM/DD/YYYY")
          : "", //moment(value.MAIL_DATE).format("MM/DD/YYYY"),
        // ADDRESSLINE2:
        //   value.ADDRESSLINE2 || value.ADDRESSLINE2 !== null
        //     ? value.ADDRESSLINE2
        //     : "NA",
      }));
    }
    return data[0]?.map((value) => ({
      ...value,
      FULLNAME: linking(
        value.FNAME + " " + value.LNAME,
        value.recipientscol.toString()
      ),
      TRIGGER_DATE: value.TRIGGER_DATE
        ? moment.utc(value.TRIGGER_DATE).format("MM/DD/YYYY")
        : "",
      //moment(value.TRIGGER_DATE).format("MM/DD/YYYY"),
      UPLOAD_DATE: value.UPLOAD_DATE
        ? moment.utc(value.UPLOAD_DATE).format("MM/DD/YYYY")
        : "",
      //moment(value.UPLOAD_DATE).format("MM/DD/YYYY"),
    }));
  }, [data, type]);
  const { userAttributes } = useAppSelector((state) => state.user);
  return (
    <Card className="m-4">
      <div className="flex justify-content-between flex-wrap card-container  w-full">
        <span
          className="flex align-items-center"
          style={{
            fontSize: "1.71428571rem",
            fontWeight: 600,
            color: AppLightTheme.color.PRIMARY,
          }}
        >
          Count of matching recipients :{" "}
          {(data instanceof Array && data[1][0].count) || 0}
        </span>
        <Button
          label="Export"
          icon="pi pi-file-excel"
          tooltip="Export to csv"
          tooltipOptions={{ position: "left" }}
          onClick={onExportCsv}
          style={{
            color: "white",
            backgroundColor: AppLightTheme.color.PRIMARY,
            borderColor: AppLightTheme.borderColor.PRIMARY,
          }}
        />
      </div>
      {data instanceof Array && data[1][0].count ? (
        <>
          {type === "recipient" ? (
            <DataTable
              value={tableData}
              stripedRows
              showGridlines
              tableStyle={{ minWidth: "50rem", fontSize: "smaller" }}
            >
              <Column
                field={"FULLNAME"}
                header="Name"
                headerStyle={{ color: "black" }}
                style={{ color: AppLightTheme.color.PRIMARY }}
              ></Column>
              <Column field={"ADDRESSLINE1"} header="Address Line1"></Column>
              <Column field={"ADDRESSLINE2"} header="Address Line2"></Column>
              <Column field={"CITY"} header="City"></Column>
              <Column field={"STATE"} header="State"></Column>
              <Column field={"ZIP"} header="Zip"></Column>
              <Column field={"MAIL_DATE"} header="Mail Date"></Column>
              <Column field={"OFFER_CODE"} header="Offer Code"></Column>
            </DataTable>
          ) : (
            <DataTable
              value={tableData}
              stripedRows
              showGridlines
              tableStyle={{ minWidth: "50rem", fontSize: "smaller" }}
            >
              <Column
                field={"FULLNAME"}
                header="Name"
                headerStyle={{ color: "black" }}
                style={{ color: AppLightTheme.color.PRIMARY }}
              ></Column>
              <Column field={"ADDRESSLINE1"} header="Street"></Column>
              <Column field={"CITY"} header="City"></Column>
              <Column field={"STATE"} header="State"></Column>
              <Column field={"ZIP"} header="Zip"></Column>
              <Column field={"OFFER_CODE"} header="Offer Code"></Column>
              <Column field={"TRIGGER_DATE"} header="Trigger Date"></Column>
              {/* <Column field={"CLIENT_NAME"} header="Client Name"></Column>
              <Column field={"UPLOAD_DATE"} header="Upload Date"></Column> */}
            </DataTable>
          )}
          <div
            className="flex justify-content-center flex-wrap card-container  w-full"
            style={{ marginTop: "1.25rem" }}
          >
            <Paginator
              currentPage={currentPage}
              rowsPerPage={pageLimit}
              collectionLength={data[1][0].count}
              enable={!!onPageChanged}
              onPageChanged={(page) => onPageChanged?.(page)}
            />
          </div>
        </>
      ) : (
        <div
          className={
            userAttributes?.["custom:ResponderProfile"] !== "1"
              ? "ui hide fluid center aligned segment"
              : "ui fluid center aligned segment"
          }
        >
          <h2 className="ui icon header">
            <i className="search icon"></i>
            <div className="content">
              Search to See Results
              <div className="sub header">
                Enter first name, last name, offer code, or address to search
              </div>
            </div>
          </h2>
        </div>
      )}
    </Card>
  );
}
