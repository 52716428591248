import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Password } from "primereact/password";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import MAIN_WEB from "../../assets/images/MAIN-WEB.png";
import { AppLightTheme } from "../../utils/theme";
import VerifyCodeModal from "../modal/verifyCodeModal";

interface ILoginFormProps {
  errorMessage?: string;
  onSubmit: (username: string, password: string) => void;
}
const LoginForm: React.FC<ILoginFormProps> = ({ errorMessage, onSubmit }) => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showVerificationCodeModal, setShowVerificationCodeModal] =
    useState(false);

  function handleSubmit() {
    onSubmit(username, password);
  }
  const handleUsernameChange = (e: any) => {
    const lowercaseUsername = e.target.value.toLowerCase();
    setUsername(lowercaseUsername);
  };
  const header = (
    <div className="flex align-items-center justify-content-center flex-wrap card-container gap-4 p-4">
      <img src={MAIN_WEB} style={{ width: "150px" }} alt="Camber marketing" />
      <label
        style={{
          color: "#008db1",
          textDecoration: "underline",
          fontWeight: 600,
          fontSize: "2em",
        }}
      >
        CUSTOMER PORTAL LOGIN
      </label>
    </div>
  );
  const footer = (
    <div className="px-2">
      <Button
        className="px-0"
        label="Enter Verification Code"
        link
        style={{
          color: AppLightTheme.color.PRIMARY,
        }}
        onClick={() => setShowVerificationCodeModal(true)}
      />
      <Button
        label="LOGIN"
        style={{
          float: "right",
          backgroundColor: AppLightTheme.color.PRIMARY,
          borderColor: AppLightTheme.borderColor.PRIMARY,
        }}
        onClick={handleSubmit}
      />
    </div>
  );
  return (
    <>
      <Card
        header={header}
        footer={footer}
        style={{
          // width: "41vw",
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          backdropFilter: "blur(4px)",
        }}
      >
        <div className="px-2">
          <div className="p-inputgroup my-3">
            <span className="p-inputgroup-addon bg-white">
              <i
                className="fa-solid fa-user"
                style={{ color: AppLightTheme.color.PRIMARY }}
              ></i>
            </span>
            <InputText
              placeholder="Username"
              value={username}
              onChange={handleUsernameChange}
            />
          </div>

          <div className="p-inputgroup my-3">
            <span className="p-inputgroup-addon bg-white">
              <i
                className="fa-solid fa-key"
                style={{ color: AppLightTheme.color.PRIMARY }}
              ></i>
            </span>
            <Password
              placeholder="Password"
              feedback={false}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {errorMessage && <Message severity="error" text="Error Message" />}
        </div>
      </Card>
      <VerifyCodeModal
        show={showVerificationCodeModal}
        handleClose={() => setShowVerificationCodeModal(false)}
      />
    </>
  );
};

export default LoginForm;
