import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// import $ from "jquery";
import MAIN_WEB from "../../assets/images/MAIN-WEB.png";
import { NavigationPagePaths } from "../../router/navigationPagePath";
import { AppLightTheme } from "../../utils/theme";
import { Button } from "primereact/button";
import { useAppSelector } from "../../store";

export default function Navbar({
  onLogoutNavClick,
  onNavClick,
  onChangePasswordClick,
}: {
  onLogoutNavClick: () => void;
  onNavClick: () => void;
  onChangePasswordClick: (value: boolean) => void;
}) {
  const { auth, userAttributes } = useAppSelector((state) => state.user);
  const location = useLocation();

  useEffect(() => {
    // (
    //   $(".ui.dropdown") as JQuery<HTMLElement> & { dropdown: () => void }
    // ).dropdown();
  }, []);
  return (
    <div
      className="flex justify-content-between flex-wrap card-container"
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 3,
      }}
    >
      <div
        style={{
          width: "25em",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div
          style={{ marginLeft: "15%" }}
          className={
            location.pathname.includes(NavigationPagePaths.HOME)
              ? "active menu item"
              : "inactive item"
          }
        >
          <Link to={NavigationPagePaths.HOME}>
            <img
              className="ui small image"
              style={{ width: "100px", height: "4.8em" }}
              src={MAIN_WEB}
            />
          </Link>
        </div>
        <span
          style={{
            fontSize: "30px",
            cursor: "pointer",
            color: AppLightTheme.backgroundColor.NAV_BAR,
            marginLeft: "10%",
          }}
          onClick={onNavClick}
        >
          &#9776;
        </span>
      </div>
      {/* {auth?.loggedIn && (
        <>
          <Link
            to={NavigationPagePaths.RESPONDER_PROFILES}
            className={
              location.pathname.includes(NavigationPagePaths.RESPONDER_PROFILES)
                ? "active menu item"
                : "inactive item TopMenu"
            }
          >
            Search Responder Profiles
          </Link>
          <Link
            to={NavigationPagePaths.IN_MARKET_MONITORING}
            className={
              location.pathname.includes(
                NavigationPagePaths.IN_MARKET_MONITORING
              )
                ? "active menu item"
                : "inactive item TopMenu"
            }
          >
            In-Market Monitoring
          </Link>
          <Link
            to={NavigationPagePaths.RE_MARKETING}
            className={
              location.pathname.includes(NavigationPagePaths.RE_MARKETING)
                ? "active menu item"
                : "inactive item TopMenu"
            }
          >
            ReMarketing
          </Link>
          <a
            href={ExternalPaths.CALL_CAMBERMARKETING}
            target="_blank"
            className={
              location.pathname.includes(ExternalPaths.CALL_CAMBERMARKETING)
                ? "active menu item"
                : "inactive item TopMenu"
            }
            rel="noreferrer"
          >
            Campaign Call Tracking
          </a>
          <div className="right menu">
            <div>
              {userAttributes?.["custom:Camber"] === "1" && (
                <Button
                  link
                  tooltip="Camber Admin"
                  tooltipOptions={{ position: "bottom" }}
                >
                  <Link to={NavigationPagePaths.ADMIN}>
                    <i
                      className="pi pi-users"
                      style={{ color: AppLightTheme.color.PRIMARY }}
                    ></i>
                  </Link>
                </Button>
              )}
              <Button
                link
                tooltip="Change Password"
                tooltipOptions={{ position: "bottom" }}
              >
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <i
                    className="pi pi-key"
                    style={{ color: AppLightTheme.color.PRIMARY }}
                  ></i>
                </a>
              </Button>
              <Button
                link
                tooltip="Logout"
                tooltipOptions={{ position: "bottom" }}
                onClick={onLogoutNavClick}
              >
                <i
                  className="pi pi-sign-out"
                  style={{ color: AppLightTheme.color.DELETE }}
                ></i>
              </Button>
            </div>
          </div>
        </>
      )} */}
      {auth?.loggedIn && (
        <div
          className="right menu"
          style={{ display: "flex", alignItems: "center", marginRight: "1em" }}
        >
          <div>
            {userAttributes?.["custom:Camber"] === "1" && (
              <Link to={NavigationPagePaths.ADMIN}>
                <Button
                  link
                  tooltip="Camber Admin"
                  tooltipOptions={{ position: "bottom" }}
                >
                  <i
                    className="fa-solid fa-user-tie"
                    style={{
                      color: AppLightTheme.color.PRIMARY,
                      fontSize: "1.2em",
                    }}
                  ></i>
                </Button>
              </Link>
            )}
            <Button
              link
              tooltip="Change Password"
              tooltipOptions={{ position: "bottom" }}
              onClick={(e) => {
                e.stopPropagation();
                onChangePasswordClick(true);
              }}
            >
              <a>
                <i
                  className="fa-solid fa-lock"
                  style={{
                    color: AppLightTheme.color.PRIMARY,
                    fontSize: "1.2em",
                  }}
                ></i>
              </a>
            </Button>
            <Button
              link
              tooltip="Logout"
              tooltipOptions={{ position: "bottom" }}
              onClick={onLogoutNavClick}
            >
              <i
                className="fa-solid fa-power-off"
                style={{ color: AppLightTheme.color.DELETE, fontSize: "1.2em" }}
              ></i>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
