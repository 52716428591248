import React from "react";
import ReactEChart from "echarts-for-react";
const Barchart = ({ amountGraphData, selectedOption }: any) => {
  // let data = ["A", "B", "C", "D", "E"];
  console.log("selectedOptionvv", selectedOption);
  const option = {
    title: {
      // title of our chart
      text: "Amount",
      textStyle: {
        color: "black",
      },
      left: "center",
      // itemStyle: { color: "red" },
    },
    tooltip: {
      trigger: "item",
    },
    xAxis: {
      type: "category",
      data: amountGraphData?.[0]?.[0].map((i: any) => i.amount_range),

      // show: false,
      barCategoryGap: "20%",
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "black",
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        interval: 0,
        rotate: 45,
        // color: "white",
        // fontSize: "8",
        align: "right",
      },
    },
    yAxis: [
      {
        type: "value",
        position: "left",
        splitLine: {
          show: false,
        },
        // axisLabel: {
        //   color: "#fff",
        //   formatter: function (data) {
        //     return CurrecnyFormatter(data);
        //   },
        // },
      },
    ],
    series: [
      {
        data: amountGraphData?.[0]?.[0].map((i: any) => {
          return selectedOption == "in_market_triggers"
            ? i?.["count(*)"]
            : i?.total;
        }),
        type: "bar",
        barWidth: "60%",
        align: "center",
        // name: "Sales",
      },
    ],
    legend: {
      show: true,
      vertical: false,
    },
  };
  return (
    <ReactEChart style={{ height: "100%", width: "100%" }} option={option} />
  );
};

export default Barchart;
