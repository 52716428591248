import React, { useMemo } from "react";
import { generatePagesArray } from "../../utils";
interface IPageParams {
  currentPage: number;
  rowsPerPage?: number;
  collectionLength?: number;
  paginationRange?: number;
  enable?: boolean;
  className?: string;
  onPageChanged: (newPageNumber: number) => void;
}
export default function Paginator({
  currentPage,
  rowsPerPage = 1,
  collectionLength = 0,
  paginationRange = 10,
  enable = true,
  className = "",
  onPageChanged,
}: IPageParams) {
  const pages = useMemo(
    () =>
      generatePagesArray(
        currentPage,
        collectionLength,
        rowsPerPage,
        paginationRange
      ),
    [currentPage, collectionLength, rowsPerPage, paginationRange]
  );
  const totalPage = pages.length;
  return (
    <>
      {enable && totalPage > 1 && (
        <div className={"ui pagination small menu " + className}>
          <a
            className={currentPage === 1 ? "icon item disabled" : "icon item"}
            onClick={() => onPageChanged(1)}
          >
            <i className="angle double left icon"></i>
          </a>
          <a
            className={currentPage === 1 ? "icon item disabled" : "icon item"}
            onClick={() => onPageChanged(currentPage - 1)}
          >
            <i className="left chevron icon"></i>
          </a>
          {pages.map((pageNumber, index) => (
            <a
              key={index}
              className={`item ${currentPage == pageNumber ? "active" : ""} ${
                isNaN(pageNumber as number) ? "disabled" : ""
              }`}
              onClick={() => onPageChanged(pageNumber as number)}
            >
              {pageNumber}
            </a>
          ))}

          <a
            className={
              currentPage === pages.at(-1) ? "icon item disabled" : "icon item"
            }
            onClick={() => onPageChanged(currentPage + 1)}
          >
            <i className="right chevron icon"></i>
          </a>
          <a
            className={
              currentPage === pages.at(-1) ? "icon item disabled" : "icon item"
            }
            onClick={() => onPageChanged(pages.at(-1) as number)}
          >
            <i className=" angle double right icon"></i>
          </a>
        </div>
      )}
    </>
  );
}
